<template>
  <v-dialog v-model="dialog" persistent width="900">
    <v-card>
      <v-card-title class="primary white--text">
        <span>Уважаемый пользователь!</span>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="my-3 text-center">
          <span class="text-body-1 black--text">
            У Вас есть неподтвержденные заказы с Медицинским представителем:
          </span>
        </div>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="orders"
          item-key="medpredOrderId"
          show-select
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:item.dateAdded="{ item }">
            <span>{{
              new Date(item.dateAdded).toLocaleString([], {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })
            }}</span>
          </template>

          <template v-slot:item.result="{ item }">
            <span v-if="item.companyId == 13898" class="font-weight-bold">
              Сумма скидки: {{ item.discountSum | toDecimal }}
            </span>
            <span v-else class="font-weight-bold">
              Сумма бонуса: {{ item.bonusSum | toDecimal }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-1"
          text
          @click="updateOrdersStatus(1)"
          :disabled="selected.length == 0"
        >
          Отказаться
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="updateOrdersStatus(2)"
          :disabled="selected.length == 0"
        >
          Подтвердить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    orders: {
      get: function () {
        return this.$store.getters.MEDPRED_ORDERS_TO_CONFIRM;
      },
      set: function (newval) {
        this.$store.commit("SET_MEDPRED_ORDERS_TO_CONFIRM", newval);
      },
    },
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Номер заказа",
        align: "center",
        sortable: false,
        value: "medpredOrderId",
      },
      {
        text: "Дата",
        align: "center",
        sortable: false,
        value: "dateAdded",
      },
      {
        text: "Сумма заказа",
        align: "center",
        sortable: false,
        value: "orderSum",
      },
      {
        text: "",
        align: "center",
        sortable: false,
        value: "result",
      },
    ],
    selected: [],
  }),
  methods: {
    updateOrdersStatus(statusId) {
      let selectedMedpredId = this.selected.map((x) => x.medpredOrderId);
      this.$store
        .dispatch("UPDATE_MEDPRED_ORDERS_STATUS", {
          medpredOrdersIds: selectedMedpredId,
          statusId: statusId,
        })
        .then(() => {
          this.orders = this.orders.filter(
            (x) => !selectedMedpredId.includes(x.medpredOrderId)
          );
          this.selected = [];
        })
        .catch(() => {});
    },
  },
  watch: {
    orders: function () {
      if (this.orders != null && this.orders.length > 0) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
  created() {
    if (this.orders != null && this.orders.length > 0) {
      this.dialog = true;
    }
  },
};
</script>

