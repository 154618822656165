import Vue from "vue";

Vue.filter("toDate", function (value) {
  if (!value) return null;
  const [year, month, day] = value.split("-");
  return `${day}.${month}.${year}`;
});

Vue.filter("orderFoundInChat", function (value) {
  if (!value) return null;

  if (value.includes("#ORDER")) {
    var firstWordlength = value.replace(/ .*/, '').length;
    var orderId = value.replace(/ .*/, '').substring(6, firstWordlength);
    if (!orderId || isNaN(orderId))
      return false
    var message = value.substring(firstWordlength);

    return {
      orderId,
      message
    }
  }
  return false;
});

Vue.filter("emergenceOfGoodsFoundInChat", function (value) {
  if (!value) return null;

  if (value.includes("Уведомляем вас, о появление товара в прайс-листе.")) {
    var goodsFullName = value.substring(58, value.length);
    var message = value;

    return {
      goodsFullName,
      message
    }
  }
  return false;
});

Vue.filter("highlight", function (value, query) {
  return value.replace(
    new RegExp(query, "ig"),
    "<span class='font-weight-bold'>" + query + "</span>"
  );
});

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("kk-KZ", {
    style: "currency",
    currency: "KZT",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});

Vue.filter("toDecimal", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("kk-KZ", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
});

Vue.filter('toPercentage', function (value, decimals) {
  if (!value) {
    value = 0
  }

  if (!decimals) {
    decimals = 0
  }

  value = value * 100
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  value = value + '%'
  return value
})


export default {};