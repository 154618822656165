import store from './../index';
import { Language } from '../../config';

export default {
    state: {
        currentLanguage: Language.defaultLanguage,
        stringValues: []
    },
    getters: {
        CURRENT_LANG: state => {
            return state.currentLanguage
        },
        STRING_VALUES: state => {
            return state.stringValues
        },
    },
    mutations: {
        SET_CURRENT_LANG: (state, payload) => {
            localStorage.setItem('CURRENT_LANG', JSON.stringify(payload))
            state.currentLanguage = payload
        },
        SET_STRING_VALUES: (state, payload) => {
            state.stringValues = payload
        },
    },
    actions: {
        COMMIT_STRING_VALUES: (commit, payload) => {
            store.commit('SET_STRING_VALUES', payload)
        },
    }
}