<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step
        :complete="step > 1"
        step="1"
        :rules="[() => userIndex != null]"
      >Выберите пользователя</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="step > 2" step="2">Напишите комментарий</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <!-- Выбор пользователей -->
      <v-stepper-content step="1">
        <v-card
          elevation="4"
          class="mb-12"
          :loading="loading"
          height="400px"
          max-height="400px"
          style="overflow-y:auto"
        >
          <v-card-actions>
            <v-text-field
              label="Глобальный поиск аптек"
              prepend-icon="mdi-account-search"
              @input="UserSearch"
              v-model="userSearch"
            ></v-text-field>
          </v-card-actions>

          <v-chip-group v-model="userIndex" color="primary" column>
            <!-- Search Users -->
            <v-card-text v-if="userSearch.length">
              <v-subheader>Результаты поиска</v-subheader>
              <span v-if="!usersSearchResult.length && userSearch.length">Не найдено</span>
              <template v-else v-for="user in usersSearchResult">
                <v-chip
                  label
                  :key="user.login + 'usersSearchResult'"
                  :inner-html.prop="(user.firstName + ' ' + user.lastName + ' ('+ user.company.name+ ')') | highlight(userSearch)"
                  filter
                  outlined
                  @click="ShareForUser(user);"
                >
                  <v-icon left>mdi-account-circle-outline</v-icon>
                </v-chip>
              </template>
            </v-card-text>

            <!-- Users From Last Chats -->
            <v-card-text v-if="usersFromLastChats.length">
              <v-subheader>Недавние чаты</v-subheader>
              <template v-for="chat in usersFromLastChats">
                <v-chip
                  label
                  :key="chat.user.login + 'usersFromLastChats'"
                  filter
                  outlined
                  @click="ShareForUser(chat.user);"
                >
                  <v-icon left>mdi-account-circle-outline</v-icon>
                  {{chat.user.firstName + ' ' + chat.user.lastName}}
                </v-chip>
              </template>
            </v-card-text>

            <!-- Recommendation Users -->
            <v-card-text v-if="usersRecommendation.length">
              <v-subheader>{{ stringValues.recommended[currentLanguage.language] }}</v-subheader>
              <template v-for="user in usersRecommendation">
                <v-chip
                  label
                  :key="user.login + 'usersRecommendation'"
                  filter
                  outlined
                  @click="ShareForUser(user);"
                >
                  <v-icon left>mdi-account-circle-outline</v-icon>
                  {{user.firstName + ' ' + user.lastName}}
                </v-chip>
              </template>
            </v-card-text>
          </v-chip-group>
        </v-card>

        <v-btn color="primary" @click="userIndex != null ? step = 2 : step = 1">Продолжить</v-btn>

        <v-btn text @click="$emit('onCloseDialog')">{{ stringValues.cancel[currentLanguage.language] }}</v-btn>
      </v-stepper-content>

      <!-- Комментарий к заказу -->
      <v-stepper-content step="2">
        <v-card
          elevation="4"
          class="mb-12"
          :loading="loading"
          height="400px"
          max-height="400px"
          style="overflow-y:auto"
          dense
        >
          <span>
            Заказ с номером
            <code>{{order.id}}</code>
          </span>
          <v-card elevation="4">
            <v-simple-table height="250px" dense fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Имя товара</th>
                    <th class="text-left">{{ stringValues.price[currentLanguage.language] }}</th>
                    <th class="text-left">{{ stringValues.count[currentLanguage.language] }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="detail in order.orderDetails" :key="detail.name">
                    <td>{{ detail.goods.fullName }}</td>
                    <td>{{ detail.price }}</td>
                    <td>{{ detail.quantity }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-card-text>
            <v-text-field label="Комментарий" v-model="outMessage"></v-text-field>
            <span
              class="caption grey--text text--darken-3"
            >Поделитесь комментарием к заказу для пользователя {{shareForUser.firstName + ' ' +shareForUser.lastName}}</span>
          </v-card-text>
        </v-card>

        <v-btn color="primary" @click="SendMessage(); $emit('onCloseDialog')">Поделится</v-btn>

        <v-btn @click="step = 1" text>Назад</v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
export default {
  props: {
    order: Object,
  },
  data: () => ({
    userIndex: null,
    step: 1,
    usersFromLastChats: [],
    usersRecommendation: [],
    userSearch: "",
    loading: false,
    usersSearchResult: [],
    shareForUser: {},
    outMessage: "",
  }),
  methods: {
    GetAllChats() {
      this.$store
        .dispatch("ALL_CHATS")
        .then((data) => {
          this.usersFromLastChats = data;
        })
        .catch(() => {});
    },

    async UserSearch() {
      this.loading = true;

      this.$store
        .dispatch("USER_SEARCH_BY_NAME", this.userSearch)
        .then((response) => {
          this.loading = false;
          this.usersSearchResult = response;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
    },

    GetCompanyUsers() {
      this.$store
        .dispatch("USERS_INSIDE_COMPANY")
        .then(() => {
          var existsUsers = this.usersFromLastChats.map((x) => x.userId);

          this.usersRecommendation = this.$store.getters.USERS.filter(
            (x) => !existsUsers.includes(x.id)
          );
        })
        .catch(() => {});
    },

    ShareForUser(user) {
      this.shareForUser = user;
    },

    SendMessage() {
      let UserMessage = {
        userId: this.shareForUser.id,
        message: "#ORDER" + this.order.id + " " + this.outMessage,
      };
      this.$store
        .dispatch("SEND_TO_ONE_CHAT", UserMessage)
        .then(() => {
          let Success = {
            text: "Собщение отправлено",
            timeout: 5000,
            color: "success",
            show: true,
          };
          this.$store.commit("SET_SNACKBAR", Success);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },

  mounted() {
    this.GetAllChats();
    this.GetCompanyUsers();
  },

  computed: {
    stringValues: {
      get() {
        return this.$store.getters.STRING_VALUES;
      },
    },
    currentLanguage: {
      get() {
        return this.$store.getters.CURRENT_LANG;
      }
    },
    currentUser: {
      get: function () {
        return this.$store.getters.USER;
      },
    },
  },
};
</script>