import axios from "axios";
import store from './../index'

export default {
  state: {
    distributors: null,
    filials: null
  },
  getters: {
    DISTRIBUTORS: state => {
      return state.distributors;
    },
    DISTRIBUTORS_BY_USER_COUNTRY: state => {
      return state.distributors;
    },
    DISTRIBUTORS_BY_CONTEXT: state => {
      return state.distributors;
    },
    FILIALS_BY_DISTRIBUTOR_ID: state => (id) => {
      return state.distributors.find(i => i.id === id);
    },
    FILIALS: state => {
      return state.filials;
    },
    FILIALS_BY_CONTEXT: state => {
      return state.filials;
    },
    FILIALS_BY_CITIES_ID: state => (ids) => {
      return state.filials.filter(i => ids.includes(i.cityId))
    }
  },
  mutations: {
    SET_DISTRIBUTORS: (state, payload) => {
      state.distributors = payload;
    },
    SET_DISTRIBUTORS_BY_USER_COUNTRY: (state, payload) => {
      state.distributors = payload;
    },
    SET_FILIALS: (state, payload) => {
      state.filials = payload;
    },
  },
  actions: {
    DISTRIBUTORS: (commit, payload) => {
      if (store.getters.DISTRIBUTORS == null)
        return new Promise((resolve, reject) => {
          axios
            .get(`/Distributor/GetAll`, payload)
            .then(({
              data,
              status
            }) => {
              if (status === 200) {
                store.commit('SET_DISTRIBUTORS', data);
                resolve(true);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
    },
    DISTRIBUTORS_BY_USER_COUNTRY: (commit, payload) => {
      if (store.getters.DISTRIBUTORS_BY_USER_COUNTRY == null)
        return new Promise((resolve, reject) => {
          axios
            .get(`/Distributor/GetByUserCountry`, payload)
            .then(({
              data,
              status
            }) => {
              if (status === 200) {
                store.commit('SET_DISTRIBUTORS_BY_USER_COUNTRY', data);
                resolve(true);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
    },
    DISTRIBUTORS_BY_CONTEXT: (commit, payload) => {
        return new Promise((resolve, reject) => {
          axios
            .get(`/Distributor/GetAllByContext`, payload)
            .then(({
              data,
              status
            }) => {
              if (status === 200) {
                store.commit('SET_DISTRIBUTORS', data);
                resolve(true);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
    },
    FILIALS: (commit, payload) => {
      if (store.getters.FILIALS == null)
        return new Promise((resolve, reject) => {
          axios
            .get(`/Distributor/GetFilials`, payload)
            .then(({
              data,
              status
            }) => {
              if (status === 200) {
                store.commit('SET_FILIALS', data);
                resolve(true);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
    },
    FILIALS_BY_CONTEXT: (commit, payload) => {
        return new Promise((resolve, reject) => {
          axios
            .get(`/Distributor/GetFilialsByContext`, payload)
            .then(({
              data,
              status
            }) => {
              if (status === 200) {
                store.commit('SET_FILIALS', data);
                resolve(true);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
    },
    FILIAL_WITH_LINES_BY_CONTEXT: (commit, payload) => {
        return new Promise((resolve, reject) => {
          axios
            .get(`/Distributor/GetFilialWithLinesByContext`, payload)
            .then(({
              data,
              status
            }) => {
              if (status === 200) {
                store.commit('SET_FILIALS', data);
                resolve(true);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
    },
    FILIAL_REQUEST_ABILITIES: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Distributor/GetFilialRequestAbilities?filialid=` + payload)
          .then(({
            data,
            status
          }) => {
            if (status === 200) {
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    UPDATE_FILIAL_REQUEST_ABILITIES: (commit, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .put(`/Distributor/UpdateFilialRequestAbilities`, payload)
          .then(({
            data,
            status
          }) => {
            if (status === 200) {
              window.console.log(data)
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    UPDATE_FILIAL_LINE: (commit, payload) => {
      return new Promise((resolve, reject) => {
          axios
              .put(`/Distributor/CreateOrUpdateFilialLine`,
                  payload
              )
              .then(({
                  status
              }) => {
                  if (status === 200) {
                      resolve(true);
                  }
              })
              .catch(error => {
                  reject(error);
              })

      });
    },
    DELETE_FILIAL_LINE: (commit, payload) => {
      return new Promise((resolve, reject) => {
          axios
              .get(`/Distributor/DeleteFilialLineById?id=` + payload)
              .then(({
                  status
              }) => {
                  if (status === 200) {
                      resolve(true);
                  }
              })
              .catch(error => {
                  reject(error);
              })

      });
    },
  }
};