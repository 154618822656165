<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <div class="ma-3">
        <v-btn text v-on="on">
          <v-icon color="primary" left>mdi-earth</v-icon>
          <span class="text-capitalize primary--text font-weight-bold">
            {{
              currentLang.language
            }}
          </span>
        </v-btn>
      </div>
    </template>
    <v-list>
      <v-list-item-group v-model="currentLang">
        <v-list-item v-for="item in languages" :key="item.id" :value="item">
          <v-list-item-content>
            <v-list-item-title v-text="item.language"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { Language } from '../../config';
export default {
  computed: {
    currentLang: {
      get: function () {
        return this.$store.getters.CURRENT_LANG;
      },
      set: function (newVal) {
        this.$store.commit("SET_CURRENT_LANG", newVal);
      }
    },
    languages() {
      return Language.list
    },
  },
  async mounted() {
    if (localStorage.getItem('CURRENT_LANG') != null) {
      this.currentLang = JSON.parse(localStorage.getItem('CURRENT_LANG'));
    }
  },
};
</script>
