<template>
  <div class="text-center">
    <v-dialog
      v-model="storageDialog"
      hide-overlay
      transition="dialog-top-transition"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span>{{ stringValues.addGoodInStore[currentLanguage.language] }}</span>
          <v-spacer />
          <v-btn icon color="red" @click="storageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="accessStoragesSelected"
                :items="accessStorages"
                item-text="storage.name"
                small-chips
                :label="stringValues.chooseStore[currentLanguage.language]"
                return-object
                :prepend-icon="'mdi-database-import'"
                hint="Для добавления товаров"
                persistent-hint
                :error-messages="modelState['StorageId']"
                dense
                outlined
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex align-center">
              <v-text-field
                type="number"
                :label="stringValues.count[currentLanguage.language]"
                v-model="storageGoodsQuantity"
                prepend-icon="mdi-border-color"
                :error-messages="modelState['GoodsQuantity']"
                hide-details
                dense
                outlined
                >Количество</v-text-field
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="accessStorages.length"
            :loading="loading.createStorageGoods"
            small
            @click="CreateStorageGoods"
            text
            color="green"
          >
            Добавить
          </v-btn>
          <v-btn v-else @click="GoToStorage" small text color="primary">
            {{ stringValues.createStore[currentLanguage.language] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: { createStorageGoods: false },
    storageGoodsQuantity: 1,
    accessStoragesSelected: [],
    modelState: {},
    abc: false,
  }),
  methods: {
    CreateStorageGoods() {
      window.console.log(this.goods);
      this.modelState = {};
      this.loading.createStorageGoods = true;

      let StorageGoods = {
        storageId: this.accessStoragesSelected?.storage?.id,
        goodsId: this.goods.id,
        goodsQuantity: this.storageGoodsQuantity,
        goodsFullName: this.goods.fullName,
      };

      this.$store
        .dispatch("CREATE_STORAGE_GOODS", StorageGoods)
        .then(() => {
          this.loading.createStorageGoods = false;
          this.storageDialog = false;
        })
        .catch((error) => {
          window.console.log("1235454");
          window.console.log(error.response.data.errors["StorageId"]);

          this.loading.createStorageGoods = false;
          if (error.response.data.errors) {
            this.modelState = error.response.data.errors;
          }
          window.console.log(this.modelState);
        });
    },
    GoToStorage() {
      this.storageDialog = false;
      this.$store.commit("SET_ORDER_LEFT_TAB", "Хранилище");
    },
  },
  computed: {
    stringValues: {
      get() {
        return this.$store.getters.STRING_VALUES;
      },
    },
    currentLanguage: {
      get() {
        return this.$store.getters.CURRENT_LANG;
      }
    },
    storageDialog: {
      get: function () {
        return this.$store.getters.STORAGE_DIALOG;
      },
      set: function (dialog) {
        this.$store.commit("SET_STORAGE_DIALOG", dialog);
      },
    },
    accessStorages: {
      get: function () {
        return this.$store.getters.ACCESS_STORAGES;
      },
    },
    goods: {
      get: function () {
        return this.$store.getters.PRICE_BY_GOODS_ID.goods;
      },
    },
  },
};
</script>