<template>
  <v-row>
    <v-col sm="12" lg="3" class="mb-4 controls">
      <v-btn fab small absolute left color="primary" @click="$refs.calendar.prev()">
        <v-icon dark>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn fab small absolute right color="primary" @click="$refs.calendar.next()">
        <v-icon dark>mdi-chevron-right</v-icon>
      </v-btn>
      <br />
      <br />
      <br />
      <v-select
        v-model="type"
        :items="typeOptions[currentLanguage.language]"
        label="Type"
        hide-details
        outlined
        dense
      ></v-select>
      <v-checkbox v-show="false" v-model="dark" label="Dark" hide-details></v-checkbox>
      <v-checkbox
        v-model="shortIntervals"
        :label="stringValues.shortIntervals[currentLanguage.language]"
        hide-details
      ></v-checkbox>
      <v-checkbox 
        v-model="shortMonths" 
        :label="stringValues.shortMonth[currentLanguage.language]" 
        hide-details>
      </v-checkbox>
      <v-checkbox
        v-model="shortWeekdays"
        :label="stringValues.shortWeekdays[currentLanguage.language]"
        hide-details
      ></v-checkbox>
      <v-menu
        ref="startMenu"
        v-model="startMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="start"
        transition="scale-transition"
        min-width="290px"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="start"
            class="mt-3"
            :label="stringValues.dateStart[currentLanguage.language]"
            prepend-icon="mdi-calendar"
            dense
            readonly
            outlined
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="start" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="startMenu = false">
            {{stringValues.cancel[currentLanguage.language]}}
          </v-btn>
          <v-btn text color="primary" @click="$refs.startMenu.save(start)">OK</v-btn>
        </v-date-picker>
      </v-menu>
      <v-menu
        v-if="hasEnd"
        ref="endMenu"
        v-model="endMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="end"
        transition="scale-transition"
        min-width="290px"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="end"
            class="mt-3"
            label="Конец даты"
            prepend-icon="mdi-calendar"
            dense
            readonly
            outlined
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="end" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="endMenu = false">Отмена</v-btn>
          <v-btn text color="primary" @click="$refs.endMenu.save(end)">OK</v-btn>
        </v-date-picker>
      </v-menu>
      <v-menu
        ref="nowMenu"
        v-model="nowMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="now"
        transition="scale-transition"
        min-width="290px"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="now"
            class="mt-3"
            :label="stringValues.today[currentLanguage.language]"
            prepend-icon="mdi-calendar"
            dense
            readonly
            outlined
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="now" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="nowMenu = false">
            {{stringValues.cancel[currentLanguage.language]}}
          </v-btn>
          <v-btn text color="primary" @click="$refs.nowMenu.save(now)">OK</v-btn>
        </v-date-picker>
      </v-menu>
      <!-- <v-select
        v-model="mode"
        :items="modeOptions"
        dense
        outlined
        hide-details
        class="mt-3"
        label="Event Overlap Mode"
      ></v-select> -->
      <v-select
        v-model="weekdays"
        :items="weekdaysOptions[currentLanguage.language]"
        dense
        outlined
        hide-details
        class="mt-3"
        :label="stringValues.selectedDayOfWeek[currentLanguage.language]"
      ></v-select>
      <v-text-field
        v-if="type === 'custom-weekly'"
        v-model="minWeeks"
        dense
        outlined
        hide-details
        class="mt-3"
        label="Минимальные недели"
        type="number"
      ></v-text-field>
      <v-select
        v-if="hasIntervals"
        v-model="intervals"
        :items="intervalsOptions"
        dense
        outlined
        hide-details
        class="mt-3"
        label="Интервалы"
      ></v-select>
      <v-select
        v-if="type === 'custom-daily'"
        v-model="maxDays"
        :items="maxDaysOptions"
        dense
        outlined
        hide-details
        class="mt-3"
        label="# количество дней"
      ></v-select>
      <v-select
        v-if="hasIntervals"
        v-model="styleInterval"
        :items="styleIntervalOptions"
        dense
        outlined
        hide-details
        class="mt-3"
        label="Дизайн"
      ></v-select>
    </v-col>
    <v-col sm="12" lg="9" class="pl-4">
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="start"
          :type="type"
          :start="start"
          :end="end"
          :min-weeks="minWeeks"
          :max-days="maxDays"
          :now="now"
          :dark="dark"
          :weekdays="weekdays"
          :first-interval="intervals.first"
          :interval-minutes="intervals.minutes"
          :interval-count="intervals.count"
          :interval-height="intervals.height"
          :interval-style="intervalStyle"
          :show-interval-label="ShowIntervalLabel"
          :short-intervals="shortIntervals"
          :short-months="shortMonths"
          :short-weekdays="shortWeekdays"
          :color="color"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="45"
          :event-color="GetEventColor"
          @change="GetSchedulersByContext"
          @mousedown:event="StartDrag"
          @mousedown:time="StartTime"
          @mousemove:time="MouseMove"
          @mouseup:time="EndDrag"
          @mouseleave.native="CancelDrag"
          @click:event="ShowEvent"
          ><template #event="{ event, timed, eventSummary }">
            <div class="v-event-draggable" v-html="eventSummary()"></div>
            <div
              v-if="timed"
              class="v-event-drag-bottom"
              @mousedown.stop="ExtendBottom(event)"
            ></div> </template
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card dense color="grey lighten-4" min-width="350px">
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn icon @click="DeleteScheduler(selectedEvent)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-toolbar-title
                ><v-text-field v-model="selectedEvent.name"></v-text-field
              ></v-toolbar-title>
            </v-toolbar>
            <!-- <v-card-text>
              <v-text-field>{{ selectedEvent.details }}</v-text-field>
            </v-card-text> -->
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false"> Отмена </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="secondary"
                @click="
                  UpdateScheduler(selectedEvent);
                  selectedOpen = false;
                "
              >
                Изменить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
const weekdaysDefault = [0, 1, 2, 3, 4, 5, 6];

const intervalsDefault = {
  first: 0,
  minutes: 60,
  count: 24,
  height: 48,
};

const stylings = {
  default() {
    return undefined;
  },
  workday(interval) {
    const inactive =
      interval.weekday === 0 ||
      interval.weekday === 6 ||
      interval.hour < 9 ||
      interval.hour >= 17;
    const startOfHour = interval.minute === 0;
    const dark = this.dark;
    const mid = dark ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)";

    return {
      backgroundColor: inactive
        ? dark
          ? "rgba(0,0,0,0.4)"
          : "rgba(0,0,0,0.05)"
        : undefined,
      borderTop: startOfHour ? undefined : "1px dashed " + mid,
    };
  },
  past(interval) {
    return {
      backgroundColor: interval.past
        ? this.dark
          ? "rgba(0,0,0,0.4)"
          : "rgba(0,0,0,0.05)"
        : undefined,
    };
  },
};

export default {
  data: () => ({
    selectedElement: null,
    selectedEvent: {},
    selectedOpen: false,
    schedulers: [],
    dark: false,
    startMenu: false,
    start: new Date().toISOString().substr(0, 10),
    end: new Date().toISOString().substr(0, 10),
    endMenu: false,
    nowMenu: false,
    minWeeks: 1,
    now: null,
    events: [],
    colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1"],
    type: "month",
    typeOptions: {
      "RU": [
        { text: "День", value: "day" },
        { text: "4 Дня", value: "4day" },
        { text: "Неделя", value: "week" },
        { text: "Месяц", value: "month" },
        { text: "Пользовательские Дни", value: "custom-daily" },
        { text: "Пользовательские Недели", value: "custom-weekly" }
      ],
      "KZ": [
        { text: "Күн", value: "day" },
        { text: "4 күн", value: "4day" },
        { text: "Апта", value: "week" },
        { text: "Ай", value: "month" },
        { text: "Пайдаланушы күндері", value: "custom-daily" },
        { text: "Пайдаланушы апталары", value: "custom-weekly" }
      ]
    },

    mode: "stack",
    modeOptions: [
      { text: "Stack", value: "stack" },
      { text: "Column", value: "column" },
    ],
    weekdays: weekdaysDefault,
    weekdaysOptions: {
      "RU": [
        { text: "ВС - СБ", value: weekdaysDefault },
        { text: "ПН, СР, ПТ", value: [1, 3, 5] },
        { text: "ПН - ПТ", value: [1, 2, 3, 4, 5] },
        { text: "ПН - ВС", value: [1, 2, 3, 4, 5, 6, 0] },
      ],
      "KZ": [
        { text: "ЖС-СБ", value: weekdaysDefault },
        { text: "ДБ, СР, ЖМ", value: [1, 3, 5] },
        { text: "ДБ-ЖМ", value: [1, 2, 3, 4, 5] },
        { text: "ДС-ЖМ", value: [1, 2, 3, 4, 5, 6, 0] },
      ],
    },
    intervals: intervalsDefault,
    intervalsOptions: [
      { text: "Все", value: intervalsDefault },
      {
        text: "Рабочее время",
        value: { first: 16, minutes: 30, count: 20, height: 48 },
      },
    ],
    maxDays: 7,
    maxDaysOptions: [
      { text: "7 дней", value: 7 },
      { text: "5 дней", value: 5 },
      { text: "4 дней", value: 4 },
      { text: "3 дней", value: 3 },
    ],
    styleInterval: "default",
    styleIntervalOptions: [
      { text: "Все", value: "default" },
      { text: "Рабочее время", value: "workday" },
      { text: "Прошлое", value: "past" },
    ],
    color: "primary",
    shortIntervals: false,
    shortMonths: false,
    shortWeekdays: false,
  }),
  computed: {
    stringValues: {
      get() {
        return this.$store.getters.STRING_VALUES;
      },
    },
    currentLanguage: {
      get() {
        return this.$store.getters.CURRENT_LANG;
      }
    },
    intervalStyle() {
      return stylings[this.styleInterval].bind(this);
    },
    hasIntervals() {
      return (
        this.type in
        {
          week: 1,
          day: 1,
          "4day": 1,
          "custom-daily": 1,
        }
      );
    },
    hasEnd() {
      return (
        this.type in
        {
          "custom-weekly": 1,
          "custom-daily": 1,
        }
      );
    },
  },
  methods: {
    ShowEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    GetSchedulersByContext() {
      const events = [];

      this.$store
        .dispatch("GET_SCHEDULERS_BY_CONTEXT")
        .then((response) => {
          this.schedulers = response;

          for (let i = 0; i < this.schedulers.length; i++) {
            const first = new Date(this.schedulers[i].startDate);
            const second = new Date(this.schedulers[i].endDate);

            events.push({
              id: this.schedulers[i].id,
              name: this.schedulers[i].name,
              start: first,
              end: second,
              timed: true,
              color: this.schedulers[i].color,
            });
          }

          this.events = events;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    CreateScheduler() {
      let start = new Date(
        new Date(this.createEvent.start).getTime() -
          new Date(this.createEvent.start).getTimezoneOffset() * 60000
      ).toISOString();

      let end = new Date(
        new Date(this.createEvent.end).getTime() -
          new Date(this.createEvent.end).getTimezoneOffset() * 60000
      ).toISOString();

      let newSchedule = {
        name: this.createEvent.name,
        startDate: start,
        endDate: end,
        color: this.createEvent.color,
      };
      this.$store
        .dispatch("CREATE_SCHEDULER", newSchedule)
        .then((response) => {
          var lastEvent = this.events[this.events.length - 1];
          lastEvent["id"] = response;
        })
        .catch((error) => {
          window.console.log(error);
          this.events.pop();
        });
    },
    DeleteScheduler(schedule) {
      this.selectedOpen = false;
      this.$store
        .dispatch("DELETE_SCHEDULER", schedule)
        .then(() => {
          this.events.splice(
            this.events.findIndex(function (i) {
              return i.id === schedule.id;
            }),
            1
          );
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    UpdateScheduler(dragEvent) {
      let start = new Date(
        new Date(dragEvent.start).getTime() -
          new Date(dragEvent.start).getTimezoneOffset() * 60000
      ).toISOString();

      let end = new Date(
        new Date(dragEvent.end).getTime() -
          new Date(dragEvent.end).getTimezoneOffset() * 60000
      ).toISOString();

      let updateSchedule = {
        id: dragEvent.id,
        name: dragEvent.name,
        startDate: start,
        endDate: end,
        color: dragEvent.color,
      };
      this.$store
        .dispatch("UPDATE_SCHEDULER", updateSchedule)
        .then(() => {})
        .catch((error) => {
          window.console.log(error);

          this.GetSchedulersByContext(this.start, this.end);
        });
    },
    GetEventColor(event) {
      return event.color;
    },
    ShowIntervalLabel(interval) {
      return interval.minute === 0;
    },
    Rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    StartDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    StartTime(tms) {
      const mouse = this.ToTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.RoundTime(mouse);

        this.createEvent = {
          name: `Событие #${this.events.length}`,
          color: this.RndElement(this.colors),
          start: this.createStart,
          end: this.createStart,
          timed: true,
        };
        this.events.push(this.createEvent);
      }
    },
    ExtendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    MouseMove(tms) {
      const mouse = this.ToTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.RoundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.RoundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    EndDrag() {
      if (this.createEvent && !this.extendOriginal) {
        var eventName = window.prompt("Назовите событие", this.createEvent.name);
        if (eventName) {
          this.createEvent.name = eventName;
        }
        this.CreateScheduler();
      } else if (!this.createEvent && !this.extendOriginal)
        this.UpdateScheduler(this.dragEvent);
      else if (this.createEvent && this.extendOriginal)
        this.UpdateScheduler(this.createEvent);
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    CancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    RoundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    ToTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
    },
    RndElement(arr) {
      return arr[this.Rnd(0, arr.length - 1)];
    },
  },
};
</script>

<style scoped>
.controls {
  position: relative;
}
</style>
<style lang="scss" scoped>
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
