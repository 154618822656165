<template>
  <v-row>
    <v-col cols="3">
      <!-- Фильтр -->
      <v-card style="position: -webkit-sticky; position: sticky; top: 100px" shaped>
        <v-card-title>
          <v-icon large left color="primary">mdi-filter</v-icon>
          <span class="title font-weight-light">Фильтр</span>
        </v-card-title>
        <v-col class="d-flex">
          <v-text-field
            label="Фильтр заказов"
            persistent-hint
            v-model="ordersId"
            hint="Номера заказов (PHC1000, PHC1001, ...)"
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="d-flex">
          <v-autocomplete
            label="Фильтр поставщиков"
            item-text="name"
            item-value="id"
            :items="filials"
            persistent-hint
            v-model="filial"
            hint="Выберите поставщика"
            return-object
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex">
          <v-autocomplete
            label="Фильтр статуса заказа"
            item-text="status"
            item-value="id"
            :items="statuses"
            v-model="status"
            persistent-hint
            hint="Выберите статус заказа"
            return-object
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex">
          <v-menu
            ref="menu.sentDateFrom"
            v-model="menu.sentDateFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="sentDateFromFormatted"
                label="Дата с"
                persistent-hint
                prepend-icon="mdi-clock-time-eight-outline"
                v-bind="attrs"
                v-on="on"
                hint="Выберите дату доставки заказа"
                clearable
                @click:clear="sentDateFrom = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="sentDateFrom"
              no-title
              @input="menu.sentDateFrom = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex">
          <v-menu
            ref="menu.sentDateTo"
            v-model="menu.sentDateTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="sentDateToFormatted"
                label="Дата по (включительно)"
                persistent-hint
                prepend-icon="mdi-clock-time-eight-outline"
                v-bind="attrs"
                v-on="on"
                hint="Выберите дату доставки заказа"
                clearable
                @click:clear="sentDateTo = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="sentDateTo"
              no-title
              @input="menu.sentDateTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex">
          <v-btn
            :loading="loading.getAllOrders"
            :disabled="loading.getAllOrders"
            @click="GetAllOrders()"
            block
            color="primary"
          >
            Поиск заказов
            <template v-slot:loader>
              <span>Ведётся поиск...</span>
            </template>
          </v-btn>
        </v-col>
      </v-card>
    </v-col>
    <v-col cols="9">
      <v-alert v-if="ordersIsEmpty" border="bottom" colored-border elevation="2"
        >Заказов не найдено, попробуйте изменить фильтр.</v-alert
      >
      <v-sheet v-if="loading.getAllOrders">
        <v-skeleton-loader
          v-for="n in 6"
          :key="n"
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto mb-3"
          elevation="2"
          transition="scale-transition"
        ></v-skeleton-loader>
      </v-sheet>
      <!-- Заказы -->
      <v-expansion-panels multiple focusable accordion>
        <v-expansion-panel
          class="mb-3"
          v-for="order in orders"
          :key="order.id"
          :id="`order${order.id}`"
        >
          <v-expansion-panel-header class="text-truncate">
            <!-- header -->

            <v-list dense>
              <v-list-item>
                <!-- <v-list-item-avatar>
                  <img src="" />
                </v-list-item-avatar> -->
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Номер заказа:
                    <code class="subtitle-2">{{ order.id }}</code>
                  </v-list-item-subtitle>
                  <v-list-item-title>{{ order.filial.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Дата создания:
                    {{
                      new Date(order.createdDate).toLocaleString()
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle v-if="order.dateOfTryToSend"
                    >Попытка отправки:
                    {{
                      new Date(order.dateOfTryToSend).toLocaleString()
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-title v-if="order.comment"
                    >Комментарий: {{ order.comment }}</v-list-item-title
                  >
                  <v-list-item-title>
                    Пользователь (Логин):
                    <code class="subtitle-2">{{ order.user.login }}</code>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Статус заказа:
                    <code class="caption">{{ order.status }}</code>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="order.bin">
                    БИН заказа:
                    <code class="caption">{{ order.bin }}</code>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Количество товаров:
                    {{ order.orderDetails.length }}</v-list-item-subtitle
                  >
                  <v-list-item-title>
                    Сумма заказа:
                    {{
                      order.orderDetails
                        .map((x) => x.quantity * x.price)
                        .reduce(
                          (previousValue, currentValue) => previousValue + currentValue,
                          0
                        )
                        .toFixed(2)
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <!-- body -->
            <v-row>
              <!-- details operation -->
              <v-col cols="1" class="text-right">
                <v-card elevation="0" style="position: sticky; top: 0px" class="pt-16">
                  <div class="mb-1">
                    <v-menu top offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-btn
                              v-on="{ ...onTooltip, ...onMenu }"
                              fab
                              small
                              :disabled="order.selectedDetails.length == 0"
                            >
                              <v-icon>mdi-content-cut</v-icon>
                            </v-btn>
                          </template>
                          <span>Создать копию товаров в хранилище</span>
                        </v-tooltip>
                      </template>
                      <v-list dense max-width="230px">
                        <v-subheader v-if="storages.length == 0"
                          >У вас нет активных хранилище</v-subheader
                        >
                        <template v-for="(accessStorage, index) in storages">
                          <v-divider :key="index" v-if="index !== 0"></v-divider>
                          <v-list-item :key="-index" @click="0">
                            <v-list-item-content
                              @click="
                                CopyDetailsToStorage(
                                  order.selectedDetails,
                                  accessStorage.storage.id
                                )
                              "
                            >
                              <code class="subtitle-2">{{
                                accessStorage.storage.name
                              }}</code>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </div>
                </v-card>
              </v-col>

              <!-- details -->
              <v-col cols="10">
                <v-data-table
                  filterable
                  v-model="order.selectedDetails"
                  :headers="headers"
                  :items="order.orderDetails"
                  dense
                  show-select
                  item-key="id"
                  disable-pagination
                  hide-default-footer
                >
                  <!-- <template v-slot:item.id="{ item }">
                <v-chip>
                  {{
                  order.orderDetails.map(
                  function(x) {
                  return x.id;
                  }).indexOf(item.id) + 1
                  }}
                </v-chip>
                  </template>-->

                  <!-- <template v-slot:item.price="{ item }">
                <div class="text-no-wrap">
                  {{ item.price }}
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        v-on="on"
                        @click="GetPriceByGoodsId(item, order.filialId)"
                      >mdi-redo</v-icon>
                    </template>
                    <v-simple-table fixed-header max-height="300px" dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Поставщик</th>
                            <th class="text-left">Цена</th>
                            <th class="text-left">Остатки</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="price in goodsPrices"
                            :key="price.distributorGoodsId"
                            @click="SelectFilial(price, item.quantity)"
                          >
                            <td>{{ price.filial.name }}</td>
                            <td>{{ price.goodsPrice }}</td>
                            <td>{{ price.stored }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-menu>
                </div>
                  </template>-->

                  <template v-slot:item.sum="{ item }">{{
                    Number(item.quantity * item.price).toFixed(2)
                  }}</template>
                  <template v-slot:item.discount="{ item }">
                    {{ `${item.discount} %` }}
                  </template>
                  <template v-slot:item.shelfLife="{ item }">{{
                    item.shelfLife.substr(0, 10) | toDate
                  }}</template>

                  <template v-slot:footer>
                    <v-divider></v-divider>
                    <v-layout justify-end>
                      <v-chip class="ma-2" label justify-end>
                        <v-icon left>mdi-summit</v-icon>
                        Общая сумма:
                        {{
                          order.orderDetails
                            .map((x) => x.quantity * x.price)
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue,
                              0
                            )
                            .toFixed(2)
                        }}
                      </v-chip>
                    </v-layout>
                  </template>
                </v-data-table>
              </v-col>

              <!-- order operation -->
              <v-col cols="1" class="text-left">
                <v-card elevation="0" style="position: sticky; top: 0px" class="pt-5">
                  <div class="mb-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          x-small
                          @click="CopyOrder(order)"
                          :loading="loading.copyOrder && order.id == currentOrder.id"
                          :disabled="loading.copyOrder && order.id == currentOrder.id"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>Создать копию заказа</span>
                    </v-tooltip>
                  </div>
                  <div class="mb-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          x-small
                          fab
                          @click="
                            GetAPIRequest(order.id, 'SEND_ORDER', (filialId = null))
                          "
                          :loading="loading.copyOrder && order.id == currentOrder.id"
                          :disabled="loading.copyOrder && order.id == currentOrder.id"
                        >
                          <v-icon>mdi-radiology-box</v-icon>
                        </v-btn>
                      </template>
                      <span>Журнал отправки заказа</span>
                    </v-tooltip>
                  </div>
                  <div class="mb-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          x-small
                          @click="
                            GetAPIRequest(order.id, 'SHIPPING_ADDRESS', (filialId = null))
                          "
                          :loading="loading.copyOrder && order.id == currentOrder.id"
                          :disabled="loading.copyOrder && order.id == currentOrder.id"
                        >
                          <v-icon>mdi-radiology-box-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Журнал выгрузки адресов</span>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-dialog
      v-model="apiRequestDialog"
      hide-overlay
      class="elevation-20"
      transition="scale-transition"
      width="1200px"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar dense>
          <v-btn icon @click="apiRequestDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Журнал</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn text @click="apiRequestDialog = false">Закрыть</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-timeline dense>
            <v-timeline-item small>
              <v-card>
                <v-card-title>userId</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.userId }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>requestProtocol</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.requestProtocol }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>requestType</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.requestType }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>request</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.request }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>requestBody</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.requestBody }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>requestBIN</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.requestBIN }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>requestDateTime</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{
                      new Date(apiRequest.requestDateTime).toLocaleString()
                    }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>responseStatusCode</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.responseStatusCode }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
            <v-timeline-item small>
              <v-card>
                <v-card-title>response</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="10">{{ apiRequest.response }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data: (vm) => ({
    sentDateFrom: null,
    sentDateTo: null,
    sentDateFromFormatted: vm.$options.filters.toDate(),
    sentDateToFormatted: vm.$options.filters.toDate(),
    menu: { sentDateFrom: false, sentDateTo: false },
    goodsPrices: [],

    loading: {
      copyOrder: false,
      GetAllOrders: false,
    },

    apiRequest: {},
    apiRequestDialog: false,
    dialog: {},
    show: {},
    currentOrder: {
      id: -1,
      filial: {
        name: "",
      },
      createdDate: "",
      comment: "",
    },
    headers: [
      // { text: "", value: "id" },
      { text: "Название", value: "goods.fullName" },
      { text: "Форма", value: "goods.dosageForm.name" },
      // { text: "Номер", value: "goods.number" },
      { text: "Кол-во", value: "quantity" },
      { text: "Цена /Мин.", value: "price" },
      // { text: "Макс.розн.цена", value: "price2" },
      { text: "Сумма", value: "sum" },
      { text: "Скидка", value: "discount" },
      { text: "Срок годности", value: "shelfLife" },
    ],
    filials: [],
    statuses: [],
    filial: null,
    ordersId: null,
    status: null,
    options: {
      page: 1,
      itemsPerPage: 50,
    },
    ordersIsEmpty: false,
    orders: [],
  }),
  methods: {
    GetAllOrders() {
      this.orders = [];
      this.ordersIsEmpty = false;
      this.loading.getAllOrders = true;
      let Pagination = {
        Take: `${this.options.itemsPerPage}`,
        Skip: `${(this.options.page - 1) * this.options.itemsPerPage}`,
      };

      let SearchProperty = {
        FilialId: `${this.filial?.id ?? ""}`,
        StatusId: `${this.status?.id ?? ""}`,
        SentDateFrom: `${this.sentDateFrom ?? ""}`,
        SentDateTo: `${this.sentDateTo ?? ""}`,
        OrdersId: this.ordersId?.split(","),
      };

      let OrdersSearch = {
        Pagination,
        SearchProperty,
      };
      this.$store
        .dispatch("ALL_ORDERS", OrdersSearch)
        .then((data) => {
          this.loading.getAllOrders = false;
          this.orders = data;
          if (!this.orders.length) this.ordersIsEmpty = true;
        })
        .catch(() => {
          this.ordersIsEmpty = true;
          this.loading.getAllOrders = false;
        });
    },
    CopyOrder(order) {
      this.$store
        .dispatch("CLONE_ORDER", order.id)
        .then(() => {})
        .catch(() => {
          this.loading.copyOrder = false;
        });
    },
    CopyDetailsToStorage(details, toStorageId) {
      window.console.log(details);
      let payload = {
        toStorageId,
        details,
      };
      this.$store
        .dispatch("COPY_DETAILS_TO_STORAGE", payload)
        .then(() => {
          let Success = {
            text: "Товары были скопированы в дефектуру.",
            timeout: 5000,
            color: "success",
          };
          this.$store.commit("SET_SNACKBAR", Success);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetAPIRequest(orderId, requestType, filialId) {
      let APIRequest = {
        orderId: `${orderId ?? ""}`,
        filialId: `${filialId ?? ""}`,
        requestType,
      };
      this.$store
        .dispatch("API_REQUEST", APIRequest)
        .then((hasData) => {
          if (hasData) {
            this.apiRequest = hasData;
            this.apiRequestDialog = true;
          } else {
            let Empty = {
              text: "Журнал отсутствует",
              timeout: 5000,
              color: "warning",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Empty);
          }
        })
        .catch(() => {
          this.loading.copyOrder = false;
        });
    },
    GetPriceByGoodsId(goods, filialId) {
      window.console.log(goods);
      this.goodsPrices = [];

      let payload = {
        goodsId: goods.goodsId,
        goods: goods,
      };

      this.$store
        .dispatch("PRICE_BY_GOODS_ID", payload)
        .then(() => {
          this.goodsPrices = this.$store.getters.PRICE_BY_GOODS_ID.prices.filter(
            (x) => x.filialId !== filialId
          );
        })
        .catch(() => {});
    },
    SelectFilial(value, quantity) {
      window.console.log("value", quantity);
      let OrderDialog = {
        goodsQuantity: quantity,
        show: true,
        price: Object.assign({}, value),
      };
      this.$store.commit("SET_ORDER_DIALOG", OrderDialog);
    },
    GetFilials() {
      this.$store
        .dispatch("FILIALS")
        .then(() => {
          this.filials = this.$store.getters.FILIALS;
        })
        .catch(() => {});
    },
    GetStatuses() {
      this.$store
        .dispatch("STATUSES")
        .then((data) => {
          this.statuses = data;
        })
        .catch(() => {});
    },
    GetAccessStorages() {
      this.$store
        .dispatch("ACCESS_STORAGES")
        .then(() => {})
        .catch(() => {});
    },
  },
  created() {
    this.GetAllOrders();
    this.GetFilials();
    this.GetStatuses();
    this.GetAccessStorages();
  },
  computed: {
    storages: {
      get: function () {
        return this.$store.getters.ACCESS_STORAGES;
      },
    },
  },
  watch: {
    sentDateFrom() {
      window.console.log("this.sentDateFrom", this.sentDateFrom);
      this.sentDateFromFormatted = this.$options.filters.toDate(this.sentDateFrom);
    },
    sentDateTo() {
      this.sentDateToFormatted = this.$options.filters.toDate(this.sentDateTo);
      //
    },
  },
};
</script>
