<template>
  <v-footer padless>
    <v-card flat tile class="text-center flex">
      <v-card-text>
        <v-btn v-for="icon in icons" :key="icon" class="mx-4" icon>
          <v-icon color="primary" size="24px">{{ icon }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="pt-0">
        <v-btn
          color="primary"
          v-for="link in links"
          :key="link.ref"
          class="mx-4"
          text
          @click="
            linkBody = link.text;
            sheet = !sheet;
          "
          >{{ stringValues[`${link.ref}`][currentLanguage.language] }}</v-btn
        >
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class>
        © 2012-{{ new Date().getFullYear() }} гг.
        <strong>ТОО "PharmIT"</strong>
      </v-card-text>
    </v-card>

    <v-bottom-sheet v-model="sheet" inset persistent scrollable>
      <v-card>
        <v-toolbar dense>
          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn text @click="sheet = !sheet">Закрыть</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-sheet>
            <div class="subtitle-2">
              <pre style="white-space: pre-wrap">
        {{ linkBody }}
        </pre
              >
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-footer>
</template>

<script>
export default {
  computed: {
    stringValues: {
      get() {
        return this.$store.getters.STRING_VALUES;
      },
    },
    currentLanguage: {
      get() {
        return this.$store.getters.CURRENT_LANG;
      }
    }
  },
  data: () => ({
    sheet: false,
    linkBody: "",
    icons: [
      "mdi-facebook",
      "mdi-twitter",
      "mdi-google-plus",
      "mdi-linkedin",
      "mdi-instagram",
    ],
    links: [
      { ref: "AboutUs", text: `` },
      {
        ref: "connectWithUs",
        text: `Внутренние номера
Менеджера по работе с клиентами:
Аяжан Тажибай 8 (747) 094 11 77, вн. 409 
8 (747) 480 25 66  
Влада Зимина 8 (747) 094 11 77, вн. 403 
8 (747) 771 56 44  
Соколова Вероника 8 (747) 094 11 77 вн. 417 
8 (707) 335-14-68  
Нурахмет Ельдар 8 (747) 094 11 77 вн. 413 
8 (747) 187-40-96  
Ельназ Кусаин 8 (747) 094 11 77 вн.408 
8 (708) 668 90 37  `,
      },
      {
        ref: "privacyPolicy",
        text: `1.	Термины и определения.
1.1. База данных «Интернет-заказ аптека» (далее по тексту - база) – представляет собой согласно пп.9 ст. 2 Закона РК от 10 июня 1996 года № 6-I «Об авторском праве и смежных правах» «совокупность данных (статей, расчетов, фактов и других), представляющих по подбору и (или) расположению материалов результат творческого труда, систематизированных таким образом, чтобы эти данные могли быть найдены и обработаны с помощью электронно-вычислительной машины».
1.2. Ключ доступа к базе (далее по тексту - ключ) – включает в себя индивидуально присвоенный логин и пароль предоставляемый Стороной 1 на электронную почту Стороны 2 для доступа и пользования базой с функцией оптимизированного закупа товара. Всю информацию по количеству предоставленных ключей Администрация предоставляет клиенту путем электронной почты.
1.3. Оптимизированный заказ – система формирования закупа, которая автоматически формирует заявки от клиента к дистрибьюторам. В настройке базы Заказчик обязуется распределить дистрибьюторов на группы. Первая группа дистрибьюторы, у которых клиент закупается в первую очередь, вторая и третья группа устанавливается так же по приоритетности производимых закупов. Система запрограммирована автоматически по запросу клиента, выбрать по товарно поставщика с минимальной ценой, с учетом индивидуальной скидки, в каждой из групп приоритетности. Список товаров, которых не было в наличии в первой группе, формируются в заявках среди дистрибьюторов второй и третьей группы.

2. Предмет Договора
2.1. Администрация сайта предоставляет Клиенту индивидуально присвоенный логин и пароль (далее - ключ) для личного (некоммерческого) доступа в онлайн Базу данных «Интернет-заказ аптека», находящуюся по адресу www.pharmcenter.kz.
2.2. Администрация сайта предоставляет Клиенту функцию оптимизированного закупа, находящуюся на web ресурсе www.pharmcenter.kz при помощи, которой Клиент формирует закуп по условиям выгодной стоимости приобретения товара у приоритетных дистрибьюторов.
2.3. Клиент обязуется оплатить предоставленные услуги и принять их. Имущественные права на Базу принадлежат исключительно Администрации сайта.

3.Права и обязанности сторон
3.1.	«Администрация сайта» обязуется:
3.1.1. предоставить Клиенту информацию по присвоенному ключу для доступа в базу с функцией оптимизированного заказа в течение 3 (трех) рабочих дней после подписания настоящего Договора клиентом и получения оплаты на расчетный счет Администрации сайта.
3.1.2. обеспечить техническую поддержку базы;
3.1.3. обеспечить функционирование базы, в том числе функции оптимизированного закупа по распределенным клиентом дистрибьюторам в группы;
3.1.4. в случае несоблюдения клиентом условий Договора, временно в течение 1 (одного) месяца или полностью дисквалифицировать клиента и отключить от доступа к базе, без права подключения к базе;
3.1.5. Не несет ответственность за функционирование базы на неисправном компьютере либо на компьютере, зараженном вирусом или вирусным программным обеспечением или прочих неисправностей, допущенных по вине клиента.
3.1.6. Не разглашать информацию, полученную от клиента, третьим лицам.
3.2. Администрация сайта имеет право расторгнуть настоящий Договор в случае передачи ключа, информации содержащейся в базе третьим лицам клиентом, а также за неисполнение клиентом взятых на себя обязательств, вытекающих из условий настоящего Договора;
3.3 «Клиент» обязуется:
3.3.1. осуществлять своевременную оплату
3.3.2. при приобретении дополнительного ключа для доступа к базе оплатить его стоимость
3.3.3. использовать всю информацию, содержащуюся в базе, в работе, исключительно для собственных нужд в качестве инструмента закупа и распределения товара;
3.3.4. самостоятельно сформировать не более 3 (трёх) групп дистрибьюторов в зависимости от приоритета закупа, частоты приобретаемого товара у таких дистрибьюторов;
3.3.5. соблюдать указания администрации сайта по пользованию базой
3.3.6. соблюдать исключительные авторские права на базу (включая программные средства и базы данных). Получаемый в соответствии с настоящим Договором доступ к базе предназначается для использования в целях осуществления аптечного закупа лекарственных средств и прочих товаров клиентом и внутреннего некоммерческого использования. Клиент не приобретает право на его распространение, переработку, импорт, сообщения его для всеобщего пользования, включая право на публичное сообщение. Не совершать иные действия, нарушающие казахстанские и международные нормы по авторскому праву и использованию программных средств.
3.3.7. нести ответственность за действия своих сотрудников, ведущие к нарушению авторских прав администрации сайта.
3.4. Клиент вправе называть имя администрации сайта третьим лицам только в целях рекламы своей деятельности и поддержании имиджа, но обязательно со ссылкой на компанию Сто администрации сайта.
3.5. Клиент не имеет право при пользовании базой передавать, извлекать, дарить, давать во временное пользование, копировать, распространять, включая, но, не ограничиваясь, и иным образом передавать полученную информацию, содержащуюся в базе третьим лицам.
3.6. В случае необеспечения требований пп. 3.3.3., 3.3.6., 3.3.7 Клиент несет материальную ответственность в размере понесенных убытков администрацией сайта.
3.7. Предоставить администрации сайта заполняемые анкетные данные об организации, информацию о контактных лицах и прочую информацию, обеспечивающую исчерпывающие данные о клиенте и его деятельности, которые служат идентификационными данными при создании ключа доступа к базе.

4. Ответственность сторон
4.1. Администрация сайта не несет ответственности перед клиентом за качество связи, настройки интернет-браузеров и за перебои в работе, происходящие по причинам, не зависящим от администрации сайта. Доступ предоставляется, в виде, в котором он доступен на момент предоставления, без каких - либо прямых или косвенных гарантий. Администрация сайта не несет ответственности за прямой или косвенный ущерб, который может быть понесен клиентом в результате ее использования. Никакая информация, даваемая администрацией сайта, не может рассматриваться как гарантия качественного предоставления услуг в случае неработоспособности интернет-браузера.
4.2. Ни при каких обстоятельствах Администрация сайта не несет ответственность за прямой или косвенный ущерб, причиненный Клиенту в результате использования услуг, каким-либо образом или невозможности использования услуг, по причинам, не зависящим от Администрации сайта.
4.3. Администрация сайта не несет ответственность за своевременность размещения, выгрузку прайсов поставщиков, за достоверность информации, содержащейся в прайс-листах, предоставляемых дистрибьюторами. Администрация сайта, ни каким образом не несет ответственность за размещенную информацию поставщика: срок, количество товара на складе, остатки, ошибки в названии препарата и т.д.
4.4. Все предоставленные ключи являются конфиденциальной информацией. Клиент принимает на себя всю ответственность за сохранность и нераспространение своего ключа. Администрация сайта не несет ответственности, если ключом доступа клиента к базе воспользовались третьи лица по причинам, не зависящим от Администрации сайта, Клиент несет персональную ответственность за конфиденциальность этой информации.
4.5. Администрация сайта не несет ответственность за соответствие сформированных заказов, а также не несет ответственность за сформированные группы клиентом в базе.

5. Конфиденциальность
5.1. Каждая из Сторон согласилась считать текст Договора, а также весь объем информации, переданной и передаваемой Сторонами друг другу при заключении настоящего Договора и в ходе исполнения обязательств, возникающих из Договора, конфиденциальной информацией (а в пределах, допускаемых действующим законодательством - коммерческой тайной) другой Стороны. Данное ограничение не распространяется на информацию:
- подлежащую оглашению в соответствии с положениями применяемого законодательства и с существом поручений, выполняемых Стороной 1;
- общеизвестную на момент передачи, в том числе опубликованную или ставшую известной неограниченному кругу лиц без нарушения настоящего Договора и вины в этом Сторон и/или их сотрудников (специалистов).
5.2. Каждая из Сторон обязуется использовать конфиденциальную информацию исключительно в рамках выполнения совместной работы и предоставлять доступ к ней только лицам, непосредственно занимающимся указанными работами. Стороны обязуются принимать все необходимые меры, чтобы их сотрудники, консультанты, зависимые и дочерние хозяйственные организации, подрядчики сохраняли конфиденциальность вышеуказанной информации, а также защищали ее от утраты.
5.3. Каждая из Сторон обязуется возместить другой Стороне в полном объеме все убытки, причиненные последней разглашением ее конфиденциальной информации в нарушение настоящей статьи Договора.
5.4. Факт существования Договора и наименование входящих в него услуг не являются конфиденциальной информацией.

6. Системные требования
6.1. Программное обеспечение эффективно работает при следующих системных требованиях к компьютеру клиента:
- оперативная память не менее 1024Mb, P4;
- операционная система Windows 7 и выше;
- пакет Microsoft Office, браузер Internet Explorer 8 и выше или Firefox 12, Yandex Browser, Google Chrome;
- доступ к сети Internet не ниже 256 Кбит/с.
Неполная работоспособность информационного массива может быть следствием жестких условий антивирусной программы, установленной на компьютере или иных ограничений пользователя. Обращаться к разработчику следует только в том случае, если при соблюдении всех вышеперечисленных рекомендаций отчеты по-прежнему работают неполноценно или не работают вообще.


Товарищество с ограниченной ответственностью «Remarka»


Юр./Фактич Адрес: 050042 г. Алматы ул. Жандосова, 98 оф. 603 БЦ Навои Тауэрс
РНН 600300615817
БИН 130740020739
ИИК р\\сч. KZ33826A1KZTD2021305 в филиале АО «АТФБанка» г.Алматы
KZ17826A1USDD2006793 в филиале АО «АТФБанка» г. Алматы
KZ27826A1EURD2003554 в филиале АО «АТФБанка» г.Алматы
БИК ALMNKZKA`,
      },
    ],
  }),
};
</script>