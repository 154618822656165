var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-combobox',{attrs:{"items":_vm.selectedItem,"label":_vm.stringValues.hidingsColumns[_vm.currentLanguage.language],"multiple":"","small-chips":"","clearable":true,"dense":"","outlined":"","counter":19,"hide-selected":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item.text))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("mdi-close")])],1):_vm._e()]}}]),model:{value:(_vm.headers[_vm.currentLanguage.language]),callback:function ($$v) {_vm.$set(_vm.headers, _vm.currentLanguage.language, $$v)},expression:"headers[currentLanguage.language]"}}),_c('v-card-title',[_vm._v(" Аннотация "),_c('v-spacer'),_c('v-text-field',{attrs:{"clearable":"","label":_vm.stringValues.searchByName[_vm.currentLanguage.language],"prepend-icon":"mdi-cloud-search-outline","maxlength":"64"},on:{"input":_vm.loadAnnotation},model:{value:(_vm.search.Name),callback:function ($$v) {_vm.$set(_vm.search, "Name", $$v)},expression:"search.Name"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"clearable":"","label":_vm.stringValues.searchByMnn[_vm.currentLanguage.language],"prepend-icon":"mdi-search-web","maxlength":"64"},on:{"input":_vm.loadAnnotation},model:{value:(_vm.search.INN),callback:function ($$v) {_vm.$set(_vm.search, "INN", $$v)},expression:"search.INN"}}),_c('v-spacer')],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"fixed-header":"","height":"500","headers":_vm.headers[_vm.currentLanguage.language],"items":_vm.items,"multi-sort":"","dense":"","options":_vm.options,"server-items-length":_vm.total,"footer-props":{
        'items-per-page-options': [10, 20, 50],
        showFirstLastPage: true,
      },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.info",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","text":"","loading":item.info},on:{"click":function($event){return _vm.GetAnnotationsById(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.stringValues.fullDescription[_vm.currentLanguage.language]))])])]}}])}),_c('AnnotationDialog')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }