import axios from "axios";
// import store from './../index'

export default {
    state: {
        search: {
            fullName: "",
            beInThePrices: false,
            advanced: {
                INN: "",
                brand: "",
                ATC: "",
                producer: "",
                show: false
            }
        }
    },
    getters: {
        GOODS_SEARCH: state => {
            return state.search;
        },
    },
    mutations: {
        SET_GOODS_SEARCH: (state, payload) => {
            state.search = payload;
        },
    },
    actions: {
        ALL_GOODS_SEARCH_PAGING: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Goods/GetAllSearchPagingAsync`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        SPELLING_CORRECT_GOOGLE: (commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/Goods/SpellingCorrectGoogle`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
        CSE_TOKEN:(commit, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Goods/GetCseTokenGoogle`, payload)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })

            });

        },
    }
};