<template >
  <div>
    <v-combobox
      v-model="headersComputed"
      :items="selectedHeaders"
      label="Скрытие и отображение столбцов"
      multiple
      small-chips
      :clearable="true"
      dense
      outlined
      :counter="headers.length"
      :hide-selected="true"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">{{ item.text }}</span>
          <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
        </v-chip>
      </template>
    </v-combobox>
    <v-card-title>
      Пользователи
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchLogin"
        prepend-icon="mdi-cloud-search-outline"
        label="Поиск"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headersComputed"
      :items="users"
      :options.sync="options"
      :server-items-length="usersCount"
      class="elevation-2"
      :search="searchLogin"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="900px" persistent scrollable>
            <!-- <template v-slot:activator="{ on }">
              <v-btn
                v-show="checkCreateUser"
                class="mb-2"
                small
                v-on="on"
                color="primary"
                >Создать</v-btn
              >
            </template> -->
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" v-show="false">
                      <v-text-field
                        v-model="editedUser.id"
                        label="ID"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedUser.login"
                        label="Логин"
                        disabled
                        :error-messages="modelState['Login']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedUser.firstName"
                        label="Имя"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedUser.lastName"
                        label="Фамилия"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedUser.userRoleId"
                        :items="userRoles"
                        prepend-icon="mdi-shield-account-outline"
                        menu-props="auto"
                        hide-details
                        label="Роль"
                        single-line
                        disabled
                        item-text="name"
                        item-value="id"
                        :error-messages="modelState['userRoleId']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        v-model="editedUser.companyId"
                        :items="companies"
                        prepend-icon="mdi-domain"
                        label="Компания"
                        item-text="name"
                        item-value="id"
                        disabled
                        clearable
                        :error-messages="modelState['companyId']"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedUser.accessUserCitiesId"
                        prepend-icon="mdi-city-variant-outline"
                        :items="userCompanyCities"
                        menu-props="auto"
                        label="Города"
                        single-line
                        item-text="city.name"
                        item-value="city.id"
                        multiple
                        :error-messages="modelState['AccessUserCitiesId']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedUser.accessUserBINsId"
                        prepend-icon="mdi-city-variant-outline"
                        :items="userCompanyBINs"
                        menu-props="auto"
                        label="БИНы"
                        single-line
                        item-text="bin"
                        item-value="bin"
                        multiple
                        :error-messages="modelState['AccessUserBINsId']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedUser.accessUserFilialsId"
                        :items="userCompanyFilials"
                        menu-props="auto"
                        label="Филиалы"
                        single-line
                        item-text="name"
                        item-value="id"
                        multiple
                        :error-messages="modelState['AccessUserFilials']"
                        small-chips
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-show="editedIndex === -1">
                      <v-text-field
                        v-model="editedUser.password"
                        label="Пароль"
                        :error-messages="modelState['Password']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" v-show="editedIndex === -1">
                      <v-text-field
                        v-model="editedUser.confirmPassword"
                        label="Подтвердите пароль"
                        :error-messages="modelState['ConfirmPassword']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="Close">Отмена</v-btn>
                <v-btn text @click="Save">Сохранить</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDiscount"
            max-width="600px"
            persistent
            scrollable
          >
            <v-card>
              <v-card-title>Скидки</v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-chip-group
                      v-model="accessUserBINsSelection"
                      column
                      active-class="primary--text"
                    >
                      <v-chip
                        filter
                        outlined
                        v-for="accessUserBIN in userCompanyBINs"
                        :key="accessUserBIN.bin"
                      >
                        {{ accessUserBIN.bin }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
                <v-row v-show="accessUserBINsSelection > -1">
                  <v-col cols="12">
                    <v-simple-table dense height="40vh">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">Филиал</th>
                            <th class="text-center">Скидка (%)</th>
                            <th class="text-center">Действия</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in editedDiscounts.discounts"
                            v-bind:key="index"
                          >
                            <td class="text-center">
                              <v-autocomplete
                                v-model="item.filial"
                                :items="FilteredFilials(index)"
                                required
                                return-object
                                item-text="name"
                                item-value="id"
                                hide-details
                                dense
                                outlined
                              ></v-autocomplete>
                            </td>
                            <td class="text-center">
                              <v-text-field
                                required
                                type="number"
                                v-model="item.userDiscount"
                                hide-details
                                dense
                                outlined
                              ></v-text-field>
                            </td>
                            <td class="text-center">
                              <v-btn
                                x-small
                                fab
                                text
                                color="red"
                                @click="
                                  editedDiscounts.discounts.splice(index, 1)
                                "
                              >
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center" colspan="3">
                              <v-btn small text fab @click="AddDiscount()">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="Close">Отмена</v-btn>
                <v-btn text @click="CreateDiscounts">Сохранить</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <AccessDialog></AccessDialog>
        </v-toolbar>
      </template>

      <template v-slot:item.userPhones="props">
        <div
          v-for="item in props.item.userPhones"
          :key="item.phone"
          class="text-no-wrap"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Home'"
                >mdi-phone-classic</v-icon
              >
            </template>
            <span>Домашний</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Mobile'"
                >mdi-cellphone</v-icon
              >
            </template>
            <span>Мобильный</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Work'"
                >mdi-phone-outgoing</v-icon
              >
            </template>
            <span>Рабочий</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                x-small
                v-if="item.phoneType.phoneTypeName == 'Fax'"
                >mdi-printer</v-icon
              >
            </template>
            <span>Факс</span>
          </v-tooltip>
          {{ item.phone }}
        </div>
      </template>

      <template v-slot:item.userEmails="props">
        <div
          v-for="item in props.item.userEmails"
          :key="item.email"
          class="text-no-wrap"
        >
          <v-icon x-small>mdi-email</v-icon>
          {{ item.email }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="EditItem(item)">mdi-pencil</v-icon>
        <!-- <v-icon small @click="DeleteItem(item)">mdi-delete</v-icon> -->
      </template>

      <template v-slot:item.accessUserCities="props">
        <div
          v-for="item in props.item.accessUserCities"
          :key="item.id"
          class="text-no-wrap"
        >
          <span v-if="item.companyCity">
            <v-icon x-small>mdi-city</v-icon>
            {{ item.companyCity.city.name }}
          </span>
        </div>
      </template>

      <template v-slot:item.accessUserBINs="props">
        <div
          v-for="item in props.item.accessUserBINs"
          :key="item.id"
          class="text-no-wrap"
        >
          <span v-if="item.bin">
            <v-icon x-small>mdi-city</v-icon>
            {{ item.bin }}
          </span>
        </div>
      </template>

      <template v-slot:item.discounts="{ item }">
        <v-icon small class="mr-2" @click="EditDiscounts(item)"
          >mdi-pencil</v-icon
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  inject: ["theme"],
  data: () => ({
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 5,
    },
    usersCount: 0,
    modelState: {},
    userRoles: [],
    companies: [],
    editedUser: {
      id: "",
      login: "",
      firstName: "",
      lastName: "",
      userRoleId: 0,
      companyId: 0,
      password: "",
      confirmPassword: "",
      accessUserCitiesId: [],
      accessUserBINsId: [],
      accessUserFilialsId: [],
    },
    defaultUser: {
      id: "",
      login: "",
      firstName: "",
      lastName: "",
      userRoleId: 0,
      companyId: 0,
      accessUserCitiesId: [],
      accessUserBINsId: [],
      accessUserFilialsId: [],
    },
    editedDiscounts: {
      userId: "",
      discounts: [
        {
          filial: null,
          userDiscount: null,
          bin: null,
        },
      ],
    },
    defaultDiscounts: {
      userId: "",
      discounts: [
        {
          filial: null,
          userDiscount: null,
          bin: null,
        },
      ],
    },
    editedIndex: -1,
    users: [],
    dialog: false,
    dialogDiscount: false,
    searchUsers: "",
    searchLogin: "",
    headers: [
      {
        text: "Id",
        align: "left",
        sortable: true,
        value: "id",
        showHeader: false,
        exclude: false,
      },
      {
        text: "Логин",
        align: "left",
        sortable: true,
        value: "login",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Имя",
        align: "left",
        sortable: true,
        value: "firstName",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Фамилия",
        align: "left",
        sortable: true,
        value: "lastName",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Роль",
        align: "left",
        sortable: true,
        value: "userRole.name",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Дата создания",
        align: "left",
        sortable: true,
        value: "dateCreated",
        showHeader: false,
        exclude: false,
      },
      {
        text: "Кем создано (Логин)",
        align: "left",
        sortable: true,
        value: "userCreated.login",
        showHeader: false,
        exclude: false,
      },
      {
        text: "Дата изменения",
        align: "left",
        sortable: true,
        value: "changedDate",
        showHeader: false,
        exclude: false,
      },
      {
        text: "Кем изменено (Логин)",
        align: "left",
        sortable: true,
        value: "changedUser.login",
        showHeader: false,
        exclude: false,
      },
      {
        text: "Телефоны",
        align: "center",
        sortable: true,
        value: "userPhones",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Почты",
        align: "center",
        sortable: true,
        value: "userEmails",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Компания",
        align: "center",
        sortable: true,
        value: "company.name",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Город",
        align: "left",
        sortable: true,
        value: "accessUserCities",
        showHeader: true,
        exclude: false,
      },
      {
        text: "БИН",
        align: "left",
        sortable: true,
        value: "accessUserBINs",
        showHeader: true,
        exclude: false,
      },
      {
        text: "Изменение",
        value: "actions",
        sortable: false,
        showHeader: true,
        exclude: false,
      },
      {
        text: "Скидки",
        value: "discounts",
        sortable: false,
        showHeader: true,
        exclude: false,
      },
    ],
    checkCreateUser: false,
    userCompanyCities: [],
    userCompanyBINs: [],
    userCompanyFilials: [],
    accessUserBINsSelection: 0,
  }),
  async mounted() {
    this.GetUsersByCompanyId();
    this.GetUserRoles();
  },
  methods: {
    EditDiscounts(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedDiscounts.userId = item.id;
      if (item.discounts.length > 0) {
        this.editedDiscounts.discounts = item.discounts.map((discount) => ({
          filial: discount?.filial,
          userDiscount: discount?.userDiscount,
          bin: discount?.bin,
        }));
      }

      this.userCompanyBINs = item?.accessUserBINs;
      this.accessUserBINsSelection = this.userCompanyBINs.indexOf(
        this.userCompanyBINs.find((x) => x.working == true)
      );

      this.userCompanyFilials =
        item.company?.companyFilials.map((x) => x.filial) ?? [];

      this.editedDiscounts.discounts.forEach((discount) => {
        if (discount?.filial) {
          this.userCompanyFilials.push(discount.filial);
        }
      });

      this.dialogDiscount = true;
    },
    AddDiscount() {
      this.editedDiscounts.discounts.push({
        filial: null,
        userDiscount: null,
        bin: null,
      });
    },
    FilteredFilials(index) {
      // Собираем все выбранные филиалы, исключая текущий элемент, который редактируется
      const selectedFilials = this.editedDiscounts.discounts
        .filter((_, i) => i !== index)
        .map((discount) => discount.filial?.id);

      // Возвращаем список филиалов, которые еще не выбраны
      return this.userCompanyFilials.filter(
        (filial) => !selectedFilials.includes(filial.id)
      );
    },
    CreateDiscounts() {
      this.editedDiscounts.discounts = this.editedDiscounts.discounts.filter(
        (i) => i.filial !== null && i.userDiscount !== null
      );

      this.editedDiscounts.discounts.forEach((discount) => {
        discount.bin = this.userCompanyBINs[this.accessUserBINsSelection]?.bin;
      });

      this.$store
        .dispatch("UPDATE_USER_DISCOUNTS", this.editedDiscounts)
        .then((response) => {
          Object.assign(this.users[this.editedIndex], response);
          this.Close();
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.modelState = error.response.data.errors;
          }
          window.console.log(error);
        });
    },
    GetUserRoles() {
      this.$store
        .dispatch("USER_ROLES")
        .then(() => {
          this.userRoles = this.$store.getters.USER_ROLES;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetUsersByCompanyId() {
      this.loading = true;

      let Pagination = {
        Take: `${this.options.itemsPerPage}`,
        Skip: `${(this.options.page - 1) * this.options.itemsPerPage}`,
      };

      let UsersSearchProperty = {
        Login: `${this.searchLogin ?? ""}`,
        CompanyId: this.$store.getters.USER.company.id,
      };

      let UsersSearch = {
        Pagination,
        UsersSearchProperty,
      };

      this.$store
        .dispatch("USERS_BY_COMPANY_ID_PAGING", UsersSearch)
        .then((response) => {
          this.loading = false;
          this.users = response.result;
          this.usersCount = response.count;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
    },
    EditItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedUser = Object.assign({}, item);

      this.userCompanyCities =
        this.users[this.editedIndex].company?.companyCities;
      this.userCompanyBINs = this.users[this.editedIndex].company?.companyBINs;
      this.userCompanyFilials =
        this.users[this.editedIndex].company?.companyFilials.map(
          (x) => x.filial
        ) ?? [];

      this.editedUser.accessUserCitiesId = item.accessUserCities.map(
        (x) => x.cityId
      );
      this.editedUser.accessUserBINsId = item.accessUserBINs?.map((x) => x.bin);
      this.editedUser.accessUserFilialsId = item.accessUserFilials?.map(
        (x) => x.filialId
      );

      this.dialog = true;
    },
    Close() {
      this.modelState = {};
      this.dialog = false;
      this.dialogDiscount = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedDiscounts = Object.assign({}, this.defaultDiscounts);
        this.editedIndex = -1;
      });
    },
    Save() {
      if (this.editedIndex > -1) {
        this.$store
          .dispatch("UPDATE_USER", this.editedUser)
          .then((response) => {
            Object.assign(this.users[this.editedIndex], response);
            this.Close();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.modelState = error.response.data.errors;
            }
            window.console.log(error);
          });
      } else {
        this.$store
          .dispatch("CREATE_USER", this.editedUser)
          .then(() => {
            this.Close();
          })
          .catch((error) => {
            if (error.response.data.errors) {
              this.modelState = error.response.data.errors;
            }
            window.console.log(error);
          });
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.Close();
    },
    contentUpdate() {
      this.GetUsers();
      this.CheckUpdateUser();
      this.CheckCreateUser();
      this.CheckCreateUserActions();
    },
    searchLogin() {
      this.GetUsersByCompanyId();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Новый" : "Редактировать";
    },
    contentUpdate() {
      return this.$store.getters.CONTENT_UPDATE;
    },
    selectedHeaders: {
      get: function () {
        return this.headers.filter((x) => x.exclude == false);
      },
    },
    headersComputed: {
      get: function () {
        return this.headers
          .filter((x) => x.showHeader == true)
          .filter((x) => x.exclude == false);
      },
      set: function (val) {
        val.forEach(function (header) {
          header.showHeader = true;
        });

        this.headers.forEach(function (header) {
          if (!val.map((x) => x.text).includes(header.text))
            header.showHeader = false;
        });
      },
    },
  },
};
</script>