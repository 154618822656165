<template>
  <div>
    <template v-if="loadingPrice">
      <v-sheet class="px-3 pt-3 pb-3">
        <v-skeleton-loader
          class="mx-auto"
          type="table-thead"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          type="table-row-divider@5"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto"
          type="table-tfoot"
        ></v-skeleton-loader>
      </v-sheet>
    </template>
    <template v-else>
      <template v-if="goodsPrice.length">
        <!-- <v-row>
          <v-col cols="12" md="6" lg="6" xl="6" class="d-flex align-center">
            <v-select
              v-model="selectedCities"
              item-text="name"
              item-value="id"
              :items="cities"
              small-chips
              :prepend-icon="'mdi-city'"
              multiple
              dense
              persistent-hint
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>Выбрано {{ selectedCities.length }} города</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="6" class="d-flex align-center">
            <v-select
              v-model="selectedFilials"
              item-text="name"
              item-value="id"
              :items="filials"
              small-chips
              :prepend-icon="'mdi-truck'"
              multiple
              dense
              persistent-hint
              :hide-selected="filterProcessing"
              :loading="filterProcessing"
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>Выбрано {{ selectedFilials.length }} поставщиков</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row> -->
        <v-row no-gutters>
          <v-col cols="12">
            <v-simple-table dense class="my-1">
              <template v-slot:default>
                <thead class="primary">
                  <tr>
                    <th class="text-center white--text">МРЦ</th>
                    <th class="text-center white--text">МОЦ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <span
                        v-if="goodsPrice[0].goods.priceLimitation != null"
                        class="text-caption black--text"
                      >
                        {{
                          goodsPrice[0].goods.priceLimitation.retail | toDecimal
                        }}
                      </span>
                      <code v-else>н/д</code>
                    </td>
                    <td class="text-center">
                      <span
                        v-if="goodsPrice[0].goods.priceLimitation != null"
                        class="text-caption black--text"
                      >
                        {{
                          goodsPrice[0].goods.priceLimitation.trade | toDecimal
                        }}
                      </span>
                      <code v-else>н/д</code>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers[currentLanguage.language]"
          :items="goodsPriceFilter"
          item-key="[id]"
          class="elevation-3 mt-1"
          dense
          multi-sort
          :sort-by="['goodsPriceWithUserDiscount', 'stored']"
          :sort-desc="[false, true]"
          @click:row="SelectFilial"
          single-select
          hide-default-footer
          :items-per-page="500"
          fixed-header
          height="405"
          id="priceList"
        >
          <template v-slot:header.filial.distributor.name="{ header }">
            <v-select
              v-model="selectedFilials"
              item-text="name"
              item-value="id"
              :items="filials"
              small-chips
              multiple
              dense
              persistent-hint
              :hide-selected="filterProcessing"
              :loading="filterProcessing"
              hide-details
            >
              <template v-slot:append>
                <v-icon color="white">mdi-menu-down</v-icon>
              </template>
              <template v-slot:selection="{ item, index }">
                <span
                  v-if="index === 0"
                  class="primary white--text text-caption font-weight-bold"
                  >{{ stringValues.provider[currentLanguage.language] }}</span
                >
              </template>
            </v-select>
          </template>
          <template v-slot:header.filial.city.name="{ header }">
            <v-select
              v-model="selectedCities"
              item-text="name"
              item-value="id"
              :items="cities"
              small-chips
              multiple
              dense
              persistent-hint
              hide-details
            >
              <template v-slot:append>
                <v-icon color="white">mdi-menu-down</v-icon>
              </template>
              <template v-slot:selection="{ item, index }">
                <span
                  v-if="index === 0"
                  class="primary white--text text-caption font-weight-bold"
                  >{{ stringValues.city[currentLanguage.language] }}</span
                >
              </template>
            </v-select>
          </template>
          <template v-slot:item.filial.distributor.name="{ item }">
            <span class="text-caption black--text">{{
              item.filial.distributor.name
            }}</span>
            <code v-if="item.userDiscount !== 0" class="ml-1"
              >{{ item.userDiscount }}%</code
            >
            <v-tooltip top v-if="item.isPromotion">
              <template v-slot:activator="{ on }">
                <v-chip
                  x-small
                  v-on="on"
                  class="ml-1 px-1 py-1"
                  outlined
                  color="primary"
                  >акция</v-chip
                >
              </template>
              <span> {{ item.promotionDescription }}</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-chip
              x-small
              v-if="item.isntDiscount"
              class="px-1 py-1"
              outlined
              color="primary"
              >без скидки</v-chip
            >
            <v-chip
                v-if="item.isMarked"
                x-small
                color="indigo darken-3"
                outlined
                >
                <v-icon left>mdi-qrcode</v-icon>
                  Маркирован</v-chip>
            <!-- <v-tooltip top v-if="item.isPromotion">
              <template v-slot:activator="{ on }">
                <code v-on="on">акция</code>
              </template>
              <span> {{ item.promotionDescription }}</span>
            </v-tooltip> -->
          </template>
          <template v-slot:item.goodsPrice="{ item }"
            ><code v-if="item.userDiscount !== 0">{{
              item.goodsPriceWithUserDiscount | toDecimal
            }}</code>
            <span class="text-caption black--text">{{
              item.goodsPrice | toDecimal
            }}</span></template
          >

          <template v-slot:item.goods.priceLimitation.retail="{ item }">
            <span
              v-if="item.goods.priceLimitation != null"
              class="text-caption black--text"
              >{{ item.goods.priceLimitation.retail | toDecimal }}</span
            >
            <code v-else>{{ stringValues.nd[currentLanguage.language] }}</code>
          </template>

          <template v-slot:item.goods.priceLimitation.trade="{ item }">
            <span
              v-if="item.goods.priceLimitation != null"
              class="text-caption black--text"
              >{{ item.goods.priceLimitation.trade | toDecimal }}</span
            >
            <code v-else>{{ stringValues.nd[currentLanguage.language] }}</code>
          </template>

          <template v-slot:item.markup="{ item }">
            <span
              v-if="
                item.goodsPrice != null && item.goods.priceLimitation != null
              "
              class="text-caption black--text"
              >{{
                (
                  (item.goods.priceLimitation.retail * 100) / item.goodsPrice -
                  100
                ).toFixed(2) + "%"
              }}</span
            >
            <code v-else>{{ stringValues.nd[currentLanguage.language] }}</code>
          </template>

          <template v-slot:item.shelfLife="{ item }">
            <div
              :class="
                getRegDateEndDaysCount(item)
                  ? 'red white--text text-caption black--text'
                  : 'text-caption black--text'
              "
            >
              {{ item.shelfLife.substr(0, 10) | toDate }}
            </div>
          </template>

          <template v-slot:item.insertedDate="{ item }"
            ><span class="text-caption black--text">{{
              item.insertedDate.substr(0, 10) | toDate
            }}</span></template
          >

          <template v-slot:item.stored="{ item }">
            <span v-if="item.stored > 0" class="text-caption black--text">{{
              item.stored
            }}</span>
            <span v-else
              ><code>{{
                stringValues.nd[currentLanguage.language]
              }}</code></span
            >
          </template>

          <template v-slot:item.filial.city.name="{ item }">
            <span class="text-caption black--text">{{
              item.filial.city.name
            }}</span></template
          >

          <template v-slot:item.icons="{ item }">
            <template v-for="iconItem in item.priceFilialPayments">
              <v-tooltip
                bottom
                :key="iconItem.id"
                open-delay="300"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    x-small
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      SetGoodsSelectedPaymentType(
                        iconItem.filialPayment.paymentType
                      )
                    "
                  >
                    {{
                      paymentTypes.find(
                        (x) => x.id == iconItem.filialPayment.paymentType
                      ).value
                    }}
                  </v-chip>
                </template>
                <div>
                  <div v-if="iconItem.filialPayment.discount != 0">
                    Скидка {{ iconItem.filialPayment.discount + "%" }} на сумму
                    заказа
                  </div>
                  <div v-if="iconItem.filialPayment.discountMin != 0">
                    При сумме заказа ниже
                    {{ iconItem.filialPayment.sumMin }} дополнительно скидка
                    {{ iconItem.filialPayment.discountMin + "%" }}
                  </div>
                  <div v-if="iconItem.filialPayment.discountBetween != 0">
                    При сумме заказа выше {{ iconItem.filialPayment.sumMin }} и
                    ниже {{ iconItem.filialPayment.sumMax }} дополнительно
                    скидка {{ iconItem.filialPayment.discountBetween + "%" }}
                  </div>
                  <div v-if="iconItem.filialPayment.discountMax != 0">
                    При сумме заказа выше
                    {{ iconItem.filialPayment.sumMax }} дополнительно скидка
                    {{ iconItem.filialPayment.discountMax + "%" }}
                  </div>
                </div>
              </v-tooltip>
            </template>
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="850"
              persistent
            >
              <template
                v-slot:activator="{ on: dialog, attrs }"
                v-if="item.priceFilialWorkloads.length > 0"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-chip
                      color="#559EBF"
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                      @click="GetWorkloadDescription(item.id)"
                      >W</v-chip
                    >
                  </template>
                  <span>Нагрузка</span>
                </v-tooltip>
              </template>

              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="#559EBF" dark>
                    Описание нагрузки
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog.value = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <div>
                      <template v-for="(group, index) in workloadGroups">
                        <v-divider :key="'dvd_' + group.group" />
                        <div
                          :key="group.group"
                          v-if="index == 0 && group.logicalOperator == 0"
                          class="my-2 mx-auto text-overline font-weight-bold primary--text"
                        >
                          Если купите <u class="green--text">все товары</u> из
                          списка ниже:
                        </div>
                        <div
                          :key="group.group"
                          v-else-if="index == 0 && group.logicalOperator == 1"
                          class="my-2 mx-auto text-overline font-weight-bold primary--text"
                        >
                          Если купите
                          <u class="green--text">один из товаров</u> из списка
                          ниже:
                        </div>
                        <div
                          :key="group.group"
                          v-else-if="index == 1 && group.logicalOperator == 0"
                          class="my-2 mx-auto text-overline font-weight-bold primary--text"
                        >
                          То необходимо докупить
                          <u class="green--text">все товары</u> из списка ниже в
                          нагрузку:
                        </div>
                        <div
                          :key="group.group"
                          v-else-if="index == 1 && group.logicalOperator == 1"
                          class="my-2 mx-auto text-overline font-weight-bold primary--text"
                        >
                          То необходимо докупить
                          <u class="green--text">Один из товаров</u> со списка
                          ниже в нагрузку:
                        </div>
                        <div :key="group.group" v-else>ERR!</div>

                        <v-data-table
                          :headers="promoGroupHeaders"
                          :items="group.priceFilialWorkloads"
                          item-key="goodsId"
                          :key="'dvt_' + index"
                          hide-default-footer
                          :expanded="expanded"
                          show-expand
                          :single-expand="singleExpand"
                        >
                          <template v-slot:item.group="{}">
                            {{ group.group }}
                          </template>

                          <template v-slot:item.info="{ item }">
                            <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="600"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  small
                                  fab
                                  text
                                  v-on="on"
                                  v-bind="attrs"
                                  :disabled="
                                    item.scales.length > 0 ? false : true
                                  "
                                >
                                  <v-icon color="primary">mdi-poll</v-icon>
                                </v-btn>
                              </template>
                              <template v-slot:default="dialog">
                                <v-card>
                                  <v-toolbar color="primary" dark
                                    >Шкалы</v-toolbar
                                  >
                                  <v-card-text>
                                    <div>
                                      <v-simple-table>
                                        <template v-slot:default>
                                          <thead>
                                            <tr>
                                              <th class="text-center">
                                                {{
                                                  stringValues.count[
                                                    currentLanguage.language
                                                  ]
                                                }}
                                              </th>
                                              <th class="text-center">
                                                Коэффициент
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              v-for="jtem in item.scales[0]
                                                .levels"
                                              :key="jtem.scaleId"
                                            >
                                              <td class="text-center">
                                                {{ jtem.count }}
                                              </td>
                                              <td class="text-center">
                                                {{ jtem.coefficient }}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </template>
                                      </v-simple-table>
                                    </div>
                                  </v-card-text>
                                  <v-card-actions class="justify-end">
                                    <v-btn text @click="dialog.value = false"
                                      >Close</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </template>
                            </v-dialog>
                          </template>

                          <template v-slot:expanded-item="{ headers, item }">
                            <td
                              :colspan="
                                headers[currentLanguage.language].length
                              "
                            >
                              <v-simple-table
                                :key="'vst_' + item.goodsId"
                                class="mx-1 my-1"
                              >
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-center">DistrGoodId</th>
                                      <th class="text-center">Партия</th>
                                      <th class="text-center">Цена товара</th>
                                      <th class="text-center">
                                        {{
                                          stringValues.expireDate[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                      <th class="text-center">
                                        {{
                                          stringValues.remains[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                      <th class="text-center">
                                        {{
                                          stringValues.count[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                      <th class="text-center">
                                        %
                                        {{
                                          stringValues.discounts[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                      <th class="text-center">
                                        {{
                                          stringValues.priority[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                      <th class="text-center">
                                        {{
                                          stringValues.paymentType[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="goods in item.priceFilialWorkloads"
                                      :key="goods.goodsId"
                                      @click="
                                        index == 0 ||
                                        group.hasPriorities == true
                                          ? SelectFilial(goods.price, null)
                                          : ''
                                      "
                                    >
                                      <td class="text-center">
                                        {{ goods.price.distributorGoodsId }}
                                      </td>
                                      <td class="text-center">
                                        {{ goods.price.batch }}
                                      </td>
                                      <td class="text-center">
                                        {{ goods.price.goodsPrice }}
                                      </td>
                                      <td class="text-center">
                                        {{
                                          goods.price.shelfLife.substr(0, 10)
                                            | toDate
                                        }}
                                      </td>
                                      <td class="text-center">
                                        {{ goods.price.stored }}
                                      </td>
                                      <td class="text-center">
                                        {{ goods.value }}
                                      </td>
                                      <td class="text-center">
                                        {{ goods.discount }}
                                      </td>
                                      <td class="text-center">
                                        {{ goods.priority }}
                                      </td>
                                      <td class="text-center green--text">
                                        {{
                                          goods.price.priceFilialPayments
                                            .map(
                                              (x) =>
                                                paymentTypes.find(
                                                  (z) =>
                                                    z.id ==
                                                    x.filialPayment.paymentType
                                                ).value
                                            )
                                            .join()
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </td>
                          </template>
                        </v-data-table>
                      </template>
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">{{
                      stringValues.close[currentLanguage.language]
                    }}</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="850"
              persistent
            >
              <template
                v-slot:activator="{ on: dialog, attrs }"
                v-if="item.priceFilialPromos.length > 0"
              >
                <v-tooltip bottom>
                  <template #activator="{ on: tooltip }">
                    <v-chip
                      color="#6DBFB9"
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                      @click="GetPromoDescription(item.id)"
                      >A</v-chip
                    >
                  </template>
                  <span>Акция</span>
                </v-tooltip>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark
                    >Описание акции
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog.value = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <div>
                      <template v-for="(group, index) in promoGroups">
                        <v-divider :key="'dvd_' + group.group" />
                        <div
                          :key="group.group"
                          v-if="index == 0 && group.logicalOperator == 0"
                          class="my-2 mx-auto text-overline font-weight-bold primary--text"
                        >
                          Купите <u class="green--text">все товары</u> из списка
                          ниже:
                        </div>
                        <div
                          :key="group.group"
                          v-else-if="index == 0 && group.logicalOperator == 1"
                          class="my-2 mx-auto text-overline font-weight-bold primary--text"
                        >
                          Купите <u class="green--text">один из товаров</u> из
                          списка ниже:
                        </div>
                        <div
                          :key="group.group"
                          v-else-if="index == 1 && group.logicalOperator == 0"
                          class="my-2 mx-auto text-overline font-weight-bold primary--text"
                        >
                          Получите в подарок
                          <u class="green--text">все товары</u> из списка ниже:
                        </div>
                        <div
                          :key="group.group"
                          v-else-if="index == 1 && group.logicalOperator == 1"
                          class="my-2 mx-auto text-overline font-weight-bold primary--text"
                        >
                          Получите в подарок
                          <u class="green--text">один из товаров</u> из списка
                          ниже:
                        </div>
                        <div :key="group.group" v-else>ERR!</div>
                        <v-data-table
                          :headers="promoGroupHeaders"
                          :items="group.priceFilialPromos"
                          item-key="goodsId"
                          :key="'dvt_' + index"
                          hide-default-footer
                          :expanded="expanded"
                          show-expand
                          :single-expand="singleExpand"
                        >
                          <template v-slot:item.group="{}">
                            {{ group.group }}
                          </template>

                          <template v-slot:item.info="{ item }">
                            <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="600"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  small
                                  fab
                                  text
                                  v-on="on"
                                  v-bind="attrs"
                                  :disabled="
                                    item.scales.length > 0 ? false : true
                                  "
                                >
                                  <v-icon color="primary">mdi-poll</v-icon>
                                </v-btn>
                              </template>
                              <template v-slot:default="dialog">
                                <v-card>
                                  <v-toolbar color="primary" dark
                                    >Шкалы</v-toolbar
                                  >
                                  <v-card-text>
                                    <div>
                                      <v-simple-table>
                                        <template v-slot:default>
                                          <thead>
                                            <tr>
                                              <th class="text-center">
                                                {{
                                                  stringValues.count[
                                                    currentLanguage.language
                                                  ]
                                                }}
                                              </th>
                                              <th class="text-center">
                                                Коэффициент
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              v-for="jtem in item.scales[0]
                                                .levels"
                                              :key="jtem.scaleId"
                                            >
                                              <td class="text-center">
                                                {{ jtem.count }}
                                              </td>
                                              <td class="text-center">
                                                {{ jtem.coefficient }}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </template>
                                      </v-simple-table>
                                    </div>
                                  </v-card-text>
                                  <v-card-actions class="justify-end">
                                    <v-btn text @click="dialog.value = false"
                                      >Close</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </template>
                            </v-dialog>
                          </template>

                          <template v-slot:expanded-item="{ headers, item }">
                            <td
                              :colspan="
                                headers[currentLanguage.language].length
                              "
                            >
                              <v-simple-table
                                :key="'vst_' + item.goodsId"
                                class="mx-1 my-1"
                              >
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <!-- <th class="text-center">DistrGoodId</th> -->
                                      <th class="text-center">Партия</th>
                                      <th class="text-center">Цена товара</th>
                                      <th class="text-center">
                                        {{
                                          stringValues.expireDate[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                      <th class="text-center">
                                        {{
                                          stringValues.remains[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                      <th class="text-center">
                                        {{
                                          stringValues.count[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                      <th class="text-center">
                                        {{
                                          stringValues.priority[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                      <th class="text-center">
                                        {{
                                          stringValues.paymentType[
                                            currentLanguage.language
                                          ]
                                        }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="goods in item.priceFilialPromos"
                                      :key="goods.goodsId"
                                      @click="
                                        index == 0
                                          ? SelectFilial(goods.price, null)
                                          : ''
                                      "
                                    >
                                      <!-- <td class="text-center">
                                            {{ goods.price.distributorGoodsId }}
                                          </td>  -->
                                      <td class="text-center">
                                        {{ goods.price.batch }}
                                      </td>
                                      <td class="text-center">
                                        {{ goods.price.goodsPrice }}
                                      </td>
                                      <td class="text-center">
                                        {{
                                          goods.price.shelfLife.substr(0, 10)
                                            | toDate
                                        }}
                                      </td>
                                      <td class="text-center">
                                        {{ goods.price.stored }}
                                      </td>
                                      <td class="text-center">
                                        {{ goods.promoCount }}
                                      </td>
                                      <td class="text-center">
                                        {{ goods.priority }}
                                      </td>
                                      <td class="text-center green--text">
                                        {{
                                          goods.price.priceFilialPayments
                                            .map(
                                              (x) =>
                                                paymentTypes.find(
                                                  (z) =>
                                                    z.id ==
                                                    x.filialPayment.paymentType
                                                ).value
                                            )
                                            .join()
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </td>
                          </template>
                        </v-data-table>
                      </template>
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">{{
                      stringValues.close[currentLanguage.language]
                    }}</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
        </v-data-table>
        <v-card elevation="0" class="mx-0 mb-2">
          <v-card-title class="py-0">
            <span class="black--text text-overline mx-auto"
              >{{ stringValues.lastOrder[currentLanguage.language] }}
              <span class="primary--text"
                >({{
                  lastOrder == null
                    ? `${stringValues.nd[currentLanguage.language]}`
                    : lastOrder.orderDate.substr(0, 10)
                }})</span
              ></span
            >
          </v-card-title>
          <v-card-text class="px-0">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center primary white--text">
                      {{ stringValues.count[currentLanguage.language] }}
                    </th>
                    <th class="text-center primary white--text">
                      {{ stringValues.price[currentLanguage.language] }}
                    </th>
                    <th class="text-center primary white--text">
                      {{ stringValues.discount[currentLanguage.language] }}
                    </th>
                    <th class="text-center primary white--text">
                      {{ stringValues.provider[currentLanguage.language] }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <v-chip small>{{
                        lastOrder == null
                          ? `${stringValues.nd[currentLanguage.language]}`
                          : lastOrder.goodsCount
                      }}</v-chip>
                    </td>
                    <td class="text-center">
                      <v-chip small>{{
                        lastOrder == null
                          ? `${stringValues.nd[currentLanguage.language]}`
                          : lastOrder.goodsPrice
                      }}</v-chip>
                    </td>
                    <td class="text-center">
                      <v-chip small>{{
                        lastOrder == null
                          ? `${stringValues.nd[currentLanguage.language]}`
                          : lastOrder.discount
                      }}</v-chip>
                    </td>
                    <td class="text-center">
                      <v-chip small>{{
                        lastOrder == null
                          ? `${stringValues.nd[currentLanguage.language]}`
                          : lastOrder.filialName
                      }}</v-chip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <AdTemplate :adLocation="4" :goodsId="goods.id"></AdTemplate>
      </template>
      <template v-else>
        <NotInPrice></NotInPrice>
      </template>
    </template>
  </div>
</template>
<script>
import { CitiesUzb } from "../../config";

export default {
  data: () => ({
    existsOrders: [],
    dense: false,
    headers: {
      RU: [
        {
          text: "Поставщик",
          value: "filial.distributor.name",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Город",
          value: "filial.city.name",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Цена",
          value: "goodsPrice",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Остатки",
          value: "stored",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Срок год.",
          value: "shelfLife",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        // {
        //   text: "МРЦ",
        //   value: "goods.priceLimitation.retail",
        //   class: "primary white--text text-caption font-weight-bold",
        //   sortable: false,
        // },
        {
          text: "Наценка",
          value: "markup",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        // {
        //   text: "МОЦ",
        //   value: "goods.priceLimitation.trade",
        //   class: "primary white--text text-caption font-weight-bold",
        //   sortable: false,
        // },
        {
          text: "Дата цены",
          value: "insertedDate",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
      ],
      KZ: [
        {
          text: "Жеткізуші",
          value: "filial.distributor.name",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Қала",
          value: "filial.city.name",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Бағасы",
          value: "goodsPrice",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Қалдықтар",
          value: "stored",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Мерзімі жыл",
          value: "shelfLife",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        // {
        //   text: "ЕББ",
        //   value: "goods.priceLimitation.retail",
        //   class: "primary white--text text-caption font-weight-bold",
        //   sortable: false,
        // },
        {
          text: "Үстеме",
          value: "markup",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        // {
        //   text: "ЕКБ",
        //   value: "goods.priceLimitation.trade",
        //   class: "primary white--text text-caption font-weight-bold",
        //   sortable: false,
        // },
        {
          text: "Баға күні",
          value: "insertedDate",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
      ],
    },
    headers2: {
      RU: [
        {
          text: "Поставщик",
          value: "filial.distributor.name",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Город",
          value: "filial.city.name",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "",
          value: "icons",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Цена",
          value: "goodsPrice",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Остатки",
          value: "stored",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Срок год.",
          value: "shelfLife",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        // {
        //   text: "МРЦ",
        //   value: "goods.priceLimitation.retail",
        //   class: "primary white--text text-caption font-weight-bold",
        //   sortable: false,
        // },
        {
          text: "Наценка",
          value: "markup",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        // {
        //   text: "МОЦ",
        //   value: "goods.priceLimitation.trade",
        //   class: "primary white--text text-caption font-weight-bold",
        //   sortable: false,
        // },
        {
          text: "Дата цены",
          value: "insertedDate",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
      ],
      KZ: [
        {
          text: "Жеткізуші",
          value: "filial.distributor.name",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Қала",
          value: "filial.city.name",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "",
          value: "icons",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Бағасы",
          value: "goodsPrice",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Қалдықтар",
          value: "stored",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        {
          text: "Мерзімі жыл",
          value: "shelfLife",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
        // {
        //   text: "ЕББ",
        //   value: "goods.priceLimitation.retail",
        //   class: "primary white--text text-caption font-weight-bold",
        //   sortable: false,
        // },
        {
          text: "Үстеме",
          value: "markup",
          class: "primary white--text  text-caption font-weight-bold",
          sortable: false,
        },
        // {
        //   text: "ЕКБ",
        //   value: "goods.priceLimitation.trade",
        //   class: "primary white--text text-caption font-weight-bold",
        //   sortable: false,
        // },
        {
          text: "Баға күні",
          value: "insertedDate",
          class: "primary white--text text-caption font-weight-bold",
          sortable: false,
        },
      ],
    },
    filialsOfUserFilter: [],
    citiesOfUserFilter: [],
    filterProcessing: false,
    paymentTypes: [],
    selectedPaymentType: null,
    promoGroups: {},
    promoGroupHeaders: [
      { text: "Группа", value: "group" },
      { text: "Наименование товара", value: "goodsName" },
      { text: "GoodsId", value: "goodsId" },
      { text: "", align: "auto", value: "info" },
    ],
    workloadGroups: {},
    singleExpand: true,
    expanded: [],
  }),
  methods: {
    SelectFilial(value, row) {
      if (row != null) row.select(true);
      if (value.priceFilialPayments.length > 0) {
        value.priceFilialPayments.forEach((element) => {
          element.filialPayment.paymentTypeName = this.paymentTypes.find(
            (x) => x.id == element.filialPayment.paymentType
          ).type;
        });
      }

      let OrderDialog = {
        goodsQuantity: null,
        show: true,
        selectedPaymentType: this.selectedPaymentType,
        price: Object.assign({}, value),
        selectedOrder: this.$store.getters.ORDERS.filter(
          (x) =>
            x.filialId == value.filialId &&
            x.orderDetails[0].paymentType == this.selectedPaymentType
        )[0],
      };

      this.$store.commit("SET_ORDER_DIALOG", OrderDialog);

      this.selectedPaymentType = null;
    },

    GoToStorage() {
      this.$store.commit("SET_ORDER_LEFT_TAB", "Хранилище");
    },

    GetFilialsOfUserFilter() {
      this.filialsOfUserFilter = this.goodsPrice.map(
        (x) => x.filial.distributor.id
      );
    },

    GetCitiesOfUserFilter() {
      this.citiesOfUserFilter = this.goodsPrice.map((x) => x.filial.city.id);
    },

    async GetPaymentTypes() {
      await this.$store
        .dispatch("GET_ALL_PAYMENT_TYPES")
        .then((response) => {
          this.paymentTypes = response;
        })
        .catch(() => {});
    },

    SetHeader() {
      let userAccessCitiesId = this.$store.getters.USER.accessUserCities.map(
        (x) => x.cityId
      );
      let UzbCitiesId = Object.values(CitiesUzb);

      if (userAccessCitiesId.some((x) => UzbCitiesId.includes(x)))
        this.headers = this.headers2;
    },
    SetGoodsSelectedPaymentType(type) {
      this.selectedPaymentType = type;
    },
    GetPromoDescription(priceId) {
      this.$store
        .dispatch("GetPromoDescription", priceId)
        .then((response) => {
          this.promoGroups = response;
        })
        .catch(() => {});
    },
    GetWorkloadDescription(priceId) {
      this.$store
        .dispatch("GetWorkloadDescription", priceId)
        .then((response) => {
          this.workloadGroups = response;
        })
        .catch(() => {});
    },
    getRegDateEndDaysCount(item) {
      let status = false;
      var parsedDate = Date.parse(item.shelfLife);
      var currentDate = new Date();
      currentDate.setDate(
        currentDate.getDate() +
          parseInt(this.$store.getters.PRICE_SHELFLIFE_END_STATUS, 10)
      );

      if (parsedDate < currentDate) status = true;

      return status;
    },
  },
  computed: {
    stringValues: {
      get() {
        return this.$store.getters.STRING_VALUES;
      },
    },
    currentLanguage: {
      get() {
        return this.$store.getters.CURRENT_LANG;
      },
    },
    lastOrder: {
      get: function () {
        return this.$store.getters.LAST_ORDER_INFO;
      },
    },
    loadingPrice: {
      get: function () {
        return this.$store.getters.ORDER_PRICE_LOADING;
      },
    },
    goodsPrice: {
      get: function () {
        return this.$store.getters.PRICE_BY_GOODS_ID.prices;
      },
    },
    goodsPriceFilter: {
      get: function () {
        let filterFilialsId = this.filialsOfUserFilter;
        let filterCitiesId = this.citiesOfUserFilter;
        let filteredGoodsPrice = this.goodsPrice.filter(
          (x) =>
            filterFilialsId.includes(x.filial.distributor.id) &&
            filterCitiesId.includes(x.filial.city.id)
        );
        return filteredGoodsPrice;
      },
    },
    goods: {
      get: function () {
        return this.$store.getters.PRICE_BY_GOODS_ID.goods;
      },
    },
    cities: {
      get: function () {
        let citiesUnique = [];

        let citiesNotUnique = this.goodsPrice.map((x) => x.filial.city);

        citiesUnique = citiesNotUnique.reduce(
          (city, current) =>
            city.some((x) => x.id === current.id) ? city : [...city, current],
          []
        );
        return citiesUnique;
      },
    },
    selectedCities: {
      get: function () {
        let filterCities = this.citiesOfUserFilter;
        let result = this.cities.filter((x) => filterCities.includes(x.id));

        return result;
      },
      set: function (value) {
        this.citiesOfUserFilter = value;
      },
    },
    filials: {
      get: function () {
        let filialsUnique = [];

        let filialsNotUnique = this.goodsPrice.map((x) => x.filial.distributor);
        filialsUnique = filialsNotUnique.reduce(
          (filial, current) =>
            filial.some((x) => x.id === current.id)
              ? filial
              : [...filial, current],
          []
        );

        return filialsUnique;
      },
    },
    selectedFilials: {
      get: function () {
        let filterFilials = this.filialsOfUserFilter;
        let result = this.filials.filter((x) => filterFilials.includes(x.id));

        return result;
      },
      set: function (value) {
        this.filialsOfUserFilter = value;
      },
    },
  },
  watch: {
    goodsPrice: function () {
      this.GetFilialsOfUserFilter();
      this.GetCitiesOfUserFilter();
    },
  },
  async mounted() {
    this.GetFilialsOfUserFilter();
    this.GetCitiesOfUserFilter();
    await this.GetPaymentTypes();
    this.SetHeader();
  },
};
</script>
<style lang="css">
#priceList tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>