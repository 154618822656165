export const CitiesUzb = {
    Tashkent : 101
};

export const PhoneNumberFormat = {
    Kazakhstan: [3,3,4],
    Uzbekistan: [2,3,4]
};

export const Language = {
    defaultLanguage: { id: 1, language: "RU" },
    list: [
        { id: 1, language: "RU" },
        { id: 2, language: "KZ" },
    ]
};

export default {
    CitiesUzb,
    PhoneNumberFormat,
    Language
};

