<template>
  <v-row>
    <!-- Contacts -->
    <v-col cols="3" class="pr-0">
      <v-card elevation="4" :loading="loading">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">{{
              currentUser.firstName + " " + currentUser.lastName
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              currentUser.company.name
            }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar tile size="80">
            <v-img
              src="https://img2.pngio.com/profile-male-persona-profile-male-user-avatar-svg-png-icon-free-user-avatar-png-981_878.png"
            ></v-img>
          </v-list-item-avatar>
        </v-list-item>
        <v-card-actions>
          <v-text-field
            :label="stringValues.globalSearch[currentLanguage.language]"
            prepend-icon="mdi-account-search"
            @input="UserSearch"
            v-model="userSearch"
          ></v-text-field>
        </v-card-actions>

        <v-list subheader dense style="overflow-y: auto" max-height="517">
          <v-list-item-group v-model="userIndex" color="primary">
            <!-- Search Users -->
            <v-subheader v-if="userSearch.length"
              >Результаты поиска</v-subheader
            >
            <span v-if="!usersSearchResult.length && userSearch.length"
              >Не найдено</span
            >
            <template v-else v-for="user in usersSearchResult">
              <v-list-item
                :key="user.login + 'usersSearchResult'"
                @click="GetChatByRecipientUser(user)"
              >
                <v-list-item-avatar>
                  <v-img
                    src="https://img2.pngio.com/profile-male-persona-profile-male-user-avatar-svg-png-icon-free-user-avatar-png-981_878.png"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    :inner-html.prop="
                      (user.firstName + ' ' + user.lastName)
                        | highlight(userSearch)
                    "
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="user.company.name"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-badge
                  :content="user.countUnreadMessages"
                  v-if="user.countUnreadMessages > 0"
                  color="primary"
                  overlap
                >
                  <v-icon :color="'grey'">mdi-chat</v-icon>
                </v-badge>
              </v-list-item>
              <v-divider
                :key="user.login + 'usersSearchResultDivider'"
              ></v-divider>
            </template>

            <v-subheader>
              {{ stringValues.news[currentLanguage.language] }}
            </v-subheader>
            <v-list-item @click="GetArticles()">
              <v-list-item-avatar>
                <v-icon>mdi-clipboard-text</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-text="articlesInfo.title[currentLanguage.language]"
                ></v-list-item-title>
                <v-list-item-subtitle v-if="articlesInfo.subtitle">
                  {{
                    new Date(articlesInfo.subtitle).toLocaleString([], {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text v-if="articlesInfo.subtitle != null">
                  <code>{{
                    new Date(articlesInfo.subtitle).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}</code>
                  <v-badge
                    v-if="articlesInfo.haveUnreadArticles"
                    color="primary"
                    dot
                    inline
                    bordered
                  >
                  </v-badge>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>

            <!-- Users From Last Chats -->
            <v-subheader v-if="usersFromLastChats.length"
              >Недавние чаты</v-subheader
            >
            <template v-for="chat in usersFromLastChats">
              <v-list-item
                :key="chat.user.login + 'usersFromLastChats'"
                @click="GetChatByRecipientUser(chat.user)"
              >
                <v-list-item-avatar>
                  <v-img
                    src="https://img2.pngio.com/profile-male-persona-profile-male-user-avatar-svg-png-icon-free-user-avatar-png-981_878.png"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="chat.user.firstName + ' ' + chat.user.lastName"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-if="$options.filters.orderFoundInChat(chat.lastMessage)"
                    >Доступ к заказу</v-list-item-subtitle
                  >

                  <v-list-item-subtitle v-else>{{
                    chat.lastMessage
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    <code>{{
                      new Date(chat.maxDateSent).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    }}</code>
                  </v-list-item-action-text>
                  <v-badge
                    :content="chat.countUnreadMessages"
                    v-if="chat.countUnreadMessages > 0"
                    color="primary"
                    overlap
                    bottom
                    bordered
                  >
                    <v-icon :color="'grey'">mdi-chat</v-icon>
                  </v-badge>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                :key="chat.user.login + 'usersFromLastChatsDivider'"
              ></v-divider>
            </template>

            <!-- Recommendation Users -->
            <v-subheader v-if="usersRecommendation.length"
              >{{ stringValues.recommended[currentLanguage.language] }}</v-subheader
            >
            <template v-for="user in usersRecommendation">
              <v-list-item
                :key="user.login + 'usersRecommendation'"
                @click="GetChatByRecipientUser(user)"
              >
                <v-list-item-avatar>
                  <v-img
                    src="https://img2.pngio.com/profile-male-persona-profile-male-user-avatar-svg-png-icon-free-user-avatar-png-981_878.png"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="user.firstName + ' ' + user.lastName"
                  ></v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>

                <v-badge
                  :content="user.countUnreadMessages"
                  v-if="user.countUnreadMessages > 0"
                  color="primary"
                  overlap
                >
                  <v-icon :color="'grey'">mdi-chat</v-icon>
                </v-badge>
              </v-list-item>
              <v-divider
                :key="user.login + 'usersRecommendationDivider'"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>

    <!-- User Chat -->
    <v-col cols="9" class="pl-2">
      <!-- Active Chat -->
      <v-card elevation="4" v-if="recipientUser">
        <!-- User Info  -->
        <v-card-title>
          <v-list-item-avatar>
            <v-img
              src="https://img2.pngio.com/profile-male-persona-profile-male-user-avatar-svg-png-icon-free-user-avatar-png-981_878.png"
            ></v-img>
          </v-list-item-avatar>
          <span>{{
            recipientUser.firstName + " " + recipientUser.lastName
          }}</span>
          <v-spacer></v-spacer>
          <span
            class="subtitle-2 text--secondary d-inline-block text-truncate"
            style="max-width: 350px"
            >{{ recipientUser.company.name }}
            {{ recipientUser.company.address }}</span
          >
          <v-spacer></v-spacer>

          <v-btn icon large>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn icon large>
            <v-icon>mdi-attachment</v-icon>
          </v-btn>
          <v-btn icon large>
            <v-icon>mdi-apps</v-icon>
          </v-btn>
        </v-card-title>

        <!-- Chat  -->
        <v-card
          max-height="500"
          height="500"
          style="overflow-y: auto"
          id="chatHistory"
          @scroll.passive="OnScroll"
          class="pr-2 pl-2"
        >
          <v-timeline>
            <v-timeline-item
              v-for="msg in activeUserChat"
              :key="msg.chatSenderId"
              :left="currentUser.id != msg.chatSender.userId"
              :right="currentUser.id == msg.chatSender.userId"
              hide-dot
            >
              <v-card>
                <template
                  class="text--primary"
                  v-if="
                    (order = $options.filters.orderFoundInChat(
                      msg.chatSender.message
                    ))
                  "
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">Доступ к заказу</div>
                      <v-list-item-title class="mb-1">
                        {{ stringValues.orderNumber[currentLanguage.language] }}
                        <code>{{ order.orderId }}</code>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      @click="
                        GetOrder(
                          $options.filters.orderFoundInChat(
                            msg.chatSender.message
                          ).orderId
                        )
                      "
                      small
                      >Посмотреть</v-btn
                    >
                  </v-card-actions>

                  <v-divider></v-divider>

                  <v-card-text>
                    <div>{{ order.message }}</div>
                    <div class="text-right">
                      <code>{{
                        new Date(msg.chatSender.dateSent).toLocaleTimeString(
                          [],
                          {
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )
                      }}</code>
                    </div>
                  </v-card-text>
                </template>
                <template
                  class="text--primary"
                  v-else-if="
                    (emergenceOfGoods = $options.filters.emergenceOfGoodsFoundInChat(
                      msg.chatSender.message
                    ))
                  "
                >
                  <v-card-actions>
                    <v-btn
                      @click="
                        MoveToOrderRequirement(
                          $options.filters.emergenceOfGoodsFoundInChat(
                            msg.chatSender.message
                          ).goodsFullName
                        )
                      "
                      small
                      >Посмотреть товар</v-btn
                    >
                  </v-card-actions>

                  <v-divider></v-divider>

                  <v-card-text>
                    <div>
                      {{ emergenceOfGoods.message }}
                    </div>
                    <div class="text-right">
                      <code>
                        {{
                          new Date(msg.chatSender.dateSent).toLocaleTimeString(
                            [],
                            {
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )
                        }}
                      </code>
                    </div>
                  </v-card-text>
                </template>
                <v-card-text class="text--primary" v-else>
                  <div>
                    {{ msg.chatSender.message }}
                  </div>
                  <div class="text-right">
                    <code>
                      {{
                        new Date(msg.chatSender.dateSent).toLocaleTimeString(
                          [],
                          {
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )
                      }}
                    </code>
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card>

        <!-- Send Message  -->
        <v-card-actions>
          <v-row>
            <v-col cols="10">
              <v-text-field
                label="Введите сообщение"
                outlined
                shaped
                prepend-icon="mdi-email-send-outline"
                clearable
                counter="125"
                v-model="outMessage"
                @click:prepend="SendMessage()"
                @keyup.enter="SendMessage()"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-fab-transition>
                <v-btn v-show="fab" fab @click="ScrollToEnd">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <v-card elevation="4" v-else-if="showArticles">
        <!-- User Info  -->
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon large>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn icon large>
            <v-icon>mdi-attachment</v-icon>
          </v-btn>
          <v-btn icon large>
            <v-icon>mdi-apps</v-icon>
          </v-btn>
        </v-card-title>

        <!-- Chat  -->
        <v-card
          max-height="639"
          height="639"
          style="overflow-y: auto"
          class="pl-2 pr-10"
          fill-height
        >
          <v-timeline dense v-if="articles">
            <v-timeline-item
              v-for="article in articles"
              :key="article.id"
              hide-dot
            >
              <v-card-subtitle>
                {{
                  new Date(article.dateInsert).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }}
              </v-card-subtitle>
              <v-card class="elevation-4">
                <v-card-title class="text-h6 justify-center">
                  {{ article.title }}
                </v-card-title>
                <v-card-text class="text-h7">
                  {{ article.text }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-card>

      <!-- Info about chat capabilities -->
      <v-card elevation="4" v-else>
        <v-card max-height="712" height="712" style="overflow-y: auto">
          <v-timeline>
            <v-timeline-item>{{ stringValues.beginChat[currentLanguage.language] }}</v-timeline-item>
            <v-timeline-item class="text-right"
              >{{ stringValues.AnswerQuestions[currentLanguage.language] }}</v-timeline-item
            >
            <v-timeline-item>{{ stringValues.offesProducts[currentLanguage.language] }}</v-timeline-item>
            <v-timeline-item class="text-right"
              >{{ stringValues.tellAboutPromotions[currentLanguage.language] }}</v-timeline-item
            >
            <v-timeline-item>{{ stringValues.helpPeopleShop[currentLanguage.language] }}</v-timeline-item>
            <v-timeline-item class="text-right"
              >{{ stringValues.shareOrders[currentLanguage.language] }}</v-timeline-item
            >
            <v-timeline-item>{{ stringValues.offesProducts[currentLanguage.language] }}</v-timeline-item>
            <v-timeline-item class="text-right"
              >{{ stringValues.tellAboutPromotions[currentLanguage.language] }}</v-timeline-item
            >
            <v-timeline-item>{{ stringValues.helpPeopleShop[currentLanguage.language] }}</v-timeline-item>
            <v-timeline-item class="text-right"
              >{{ stringValues.shareOrders[currentLanguage.language] }}</v-timeline-item
            >
          </v-timeline>
        </v-card>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog.orderInChat" persistent max-width="800">
      <v-card dense>
        <v-card-title>
          <span class="headline">
            Заказ с номером
            <code>{{ orderInChat.id }}</code>
          </span>
        </v-card-title>
        <v-card-text>
          <v-simple-table dense fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Имя товара</th>
                  <th class="text-left">{{ stringValues.price[currentLanguage.language] }}</th>
                  <th class="text-left">{{ stringValues.count[currentLanguage.language] }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="detail in orderInChat.orderDetails"
                  :key="detail.name"
                >
                  <td>{{ detail.goods.fullName }}</td>
                  <td>{{ detail.price }}</td>
                  <td>{{ detail.quantity }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="dialog.orderInChat = false"
            >Закрыть</v-btn
          >
          <v-btn color="blue" text @click="CloneOrder()">Создать копию</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    orderInChat: {},
    userIndex: null,
    dialog: { orderInChat: false },
    usersFromLastChats: [],
    activeUserChat: [],
    usersRecommendation: [],
    outMessage: "",
    fab: false,
    userSearch: "",
    loading: false,
    usersSearchResult: [],
    recipientUser: null,
    showArticles: false,
    articlesInfo: {
      title: {
        "RU": "Новости",
        "KZ": "Жаңалықтар"
      },
      subtitle: null,
      haveUnreadArticles: false,
    },
    activeNewsChat: [],
    articles: [],
  }),
  methods: {
    CloneOrder() {
      this.$store
        .dispatch("CLONE_ORDER", this.orderInChat.id)
        .then(() => {
          this.dialog.orderInChat = false;
        })
        .catch(() => {});
    },
    MoveToOrderRequirement(goodsFullName) {
      let Search = {
        fullName: goodsFullName,
        beInThePrices: false,
        advanced: {
          INN: "",
          brand: "",
          ATC: "",
          producer: "",
          show: false,
        },
      };
      this.$store.commit("SET_GOODS_SEARCH", Search);
      this.$router.push("/Order");
    },
    GetOrder(orderId) {
      this.$store
        .dispatch("ORDER_IN_CHAT", orderId)
        .then((data) => {
          if (data) {
            this.orderInChat = {};
            this.orderInChat = data;
            this.dialog.orderInChat = true;
          } else {
            let Empty = {
              text: "Заказ не существует",
              timeout: 5000,
              color: "warning",
              show: true,
            };
            this.$store.commit("SET_SNACKBAR", Empty);
          }
        })
        .catch(() => {});
    },

    GetAllChats() {
      this.$store
        .dispatch("ALL_CHATS")
        .then((data) => {
          this.usersFromLastChats = data;
        })
        .catch(() => {});
    },

    CountUnreadMessages(user) {
      var findChatIndex = this.usersFromLastChats.findIndex(
        (x) => x.user.id == user.id
      );
      var chat = this.usersFromLastChats[findChatIndex];
      if (!chat.countUnreadMessages) chat.countUnreadMessages = 0;
      chat.countUnreadMessages = chat.countUnreadMessages + 1;
    },

    MoveUserToUp(user, lastMessage) {
      var findChatIndex = this.usersFromLastChats.findIndex(
        (x) => x.user.id == user.id
      );
      var isFirstChat = findChatIndex == -1;
      if (isFirstChat) {
        let newUserChat = {
          lastMessage: lastMessage,
          maxDateSent: new Date(),
          user: user,
        };
        this.usersFromLastChats.unshift(newUserChat);
      } else {
        var chat = this.usersFromLastChats[findChatIndex];
        chat.lastMessage = lastMessage;
        chat.maxDateSent = new Date();

        this.usersFromLastChats.splice(findChatIndex, 1);
        this.usersFromLastChats.splice(0, 0, chat);
      }
    },

    SendMessage() {
      if (/\S/.test(this.outMessage)) {
        if (!this.$store.getters.HUB_CONNECTION.state) {
          var Snackbar = {
            text: "Соединение потеряно",
            timeout: 7000,
            color: "error",
            linkText: "",
            show: true,
          };

          this.$store.commit("SET_SNACKBAR", Snackbar);

          this.$store
            .dispatch("CONNECT_HUB")
            .then(() => {
              var Snackbar = {
                text: "Соединение восстановлено.",
                timeout: 7000,
                color: "success",
                linkText: "",
                show: true,
              };

              this.$store.commit("SET_SNACKBAR", Snackbar);
            })
            .catch((error) => {
              window.console.error(error);
            });
          return;
        }

        let UserMessage = {
          userId: this.recipientUser.id,
          message: this.outMessage,
        };

        this.$store
          .dispatch("SEND_TO_ONE_CHAT", UserMessage)
          .then(() => {
            this.activeUserChat.push({
              chatSender: {
                userId: this.currentUser.id,
                message: this.outMessage,
                dateSent: new Date(),
              },
            });

            this.MoveUserToUp(this.recipientUser, this.outMessage);
            this.outMessage = "";
            this.ScrollToEnd();
          })
          .catch((error) => {
            window.console.log(error);
          });
      }
    },

    GetMessage(message, user) {
      this.MoveUserToUp(user, message);
      this.CountUnreadMessages(user);

      if (this.recipientUser?.id == user.id) {
        this.activeUserChat.push({
          chatSender: {
            userId: user.id,
            message: message,
            dateSent: new Date(),
          },
        });
        this.ScrollToEnd();
      }
    },

    async GetChatByRecipientUser(user) {
      this.showArticles = false;
      this.recipientUser = user;
      this.activeUserChat = [];
      this.$store
        .dispatch("CHAT_BY_RECIPIENT_USER", user.id)
        .then((data) => {
          this.activeUserChat = data;
          var findChatIndex = this.usersFromLastChats.findIndex(
            (x) => x.user.id == user.id
          );
          var userChat = this.usersFromLastChats[findChatIndex];
          var countUnreadMessagesUser = userChat.countUnreadMessages;
          var globalCountUnreadMessagesUser = this.$store.getters
            .COUNT_UNREAD_MESSAGES;
          this.$store.commit(
            "SET_COUNT_UNREAD_MESSAGES",
            globalCountUnreadMessagesUser - countUnreadMessagesUser
          );
          userChat.countUnreadMessages = 0;
          this.ScrollToEnd();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    async GetArticles() {
      this.articles = [];
      this.recipientUser = null;
      this.showArticles = true;
      if (this.articlesInfo.haveUnreadArticles) {
        var globalCountUnreadMessagesUser = this.$store.getters
          .COUNT_UNREAD_MESSAGES;
        this.$store.commit(
          "SET_COUNT_UNREAD_MESSAGES",
          globalCountUnreadMessagesUser - 1
        );
        this.$store.commit("SET_COUNT_UNREAD_ARTICLES", 0);
      }
      this.$store
        .dispatch("GET_ARTICLES")
        .then((data) => {
          this.articles = data;
          this.$store
            .dispatch("READ_ARTICLES")
            .then(() => {
              this.articlesInfo.haveUnreadArticles = false;
            })
            .catch();
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    LoadArticlesInformation() {
      this.$store
        .dispatch("LOAD_ARTICLE_INFO")
        .then((data) => {
          this.articlesInfo.subtitle = data.lastDayInsert;
          this.articlesInfo.haveUnreadArticles = data.haveUnreadArticles;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    GetArticle() {
      this.articlesInfo.haveUnreadArticles = true;
      if (this.showArticles == true) {
        this.GetArticles();
      }
    },
    OnScroll() {
      var container = this.$el.querySelector("#chatHistory");
      this.fab = container.scrollHeight - container.scrollTop > 500;
    },

    ScrollToEnd() {
      setTimeout(() => {
        var container = this.$el.querySelector("#chatHistory");
        container.scrollTop = container.scrollHeight;
      });
    },

    async UserSearch() {
      this.loading = true;

      this.$store
        .dispatch("USER_SEARCH_BY_NAME", this.userSearch)
        .then((response) => {
          this.loading = false;
          this.usersSearchResult = response;
        })
        .catch((error) => {
          this.loading = false;
          window.console.log(error);
        });
    },

    GetCompanyUsers() {
      this.$store
        .dispatch("USERS_INSIDE_COMPANY")
        .then(() => {
          var existsUsers = this.usersFromLastChats.map((x) => x.userId);

          this.usersRecommendation = this.$store.getters.USERS.filter(
            (x) => !existsUsers.includes(x.id)
          );
        })
        .catch(() => {});
    },
  },

  mounted() {
    this.GetAllChats();
    this.GetCompanyUsers();
    this.LoadArticlesInformation();
  },

  created() {
    this.$store.getters.HUB_CONNECTION.on(
      "ReceivedSendToEveryone",
      this.GetArticle
    );
    this.$store.getters.HUB_CONNECTION.on("ReceivedToOne", this.GetMessage);
  },
  destroyed() {
    this.showArticles = false;
  },
  computed: {
    stringValues: {
      get() {
        return this.$store.getters.STRING_VALUES;
      },
    },
    currentLanguage: {
      get() {
        return this.$store.getters.CURRENT_LANG;
      }
    },
    currentUser: {
      get: function() {
        return this.$store.getters.USER;
      },
    },
  },
};
</script>
