<template>
  <div>
    <v-row>
      <OrderLeftTabs></OrderLeftTabs>
      <OrderDialog></OrderDialog>
      <OrderRightTabs></OrderRightTabs>
    </v-row>

    <OrderListCenter v-if="userRoleId == 10"></OrderListCenter>
    <OrderList v-else-if="userRoleId != 11"></OrderList>

    <MedpredOrdersToConfirm />
  </div>
</template>
<script>
export default {
  data: () => ({
  }),
  methods: {
    GetOrders() {
      this.$store.commit("SET_ORDER_NAVIGATION", 0);
      this.$store.commit("SET_ORDER_FROM_API", true);
      if (this.$store.getters.ACTIVE_POINT != null) {
        this.$store
          .dispatch("ORDERS_BY_USER", this.$store.getters.ACTIVE_POINT)
          .then(() => {
            this.$store.commit("SET_ORDER_FROM_API", false);
          })
          .catch(() => {});
      } else {
        this.$store
          .dispatch("ORDERS")
          .then(() => {
            this.$store.commit("SET_ORDER_FROM_API", false);
          })
          .catch(() => {});
      }
    },
    GetAccessStorages() {
      this.$store
        .dispatch("ACCESS_STORAGES")
        .then(() => {})
        .catch(() => {});
    },
    GetMedpredOrdersToConfirm() {
      this.$store
        .dispatch("GET_MEDPRED_ORDERS_TO_CONFIRM")
        .then(() => {})
        .catch(() => {});
    },
  },
  mounted() {
    this.$store.commit("SET_ORDER_RIGHT_TAB", "Новинки");
    this.GetAccessStorages();
    this.GetOrders();
    this.GetMedpredOrdersToConfirm();
  },
  computed: {
    userRoleId: {
      get: function () {
        return this.$store.getters.USER.userRoleId;
      },
    },
  },
};
</script>