<template>
  <div id="form">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          {{ stringValues.pricesMenu[currentLanguage.language] }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchFilials"
            prepend-icon="mdi-cloud-search-outline"
            :label="stringValues.search[currentLanguage.language]"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            :label="stringValues.filtrSupplier[currentLanguage.language]"
            item-text="name"
            item-value="id"
            :items="distributors"
            @change="GetFilialsByDistributorId"
            persistent-hint
            :hint="stringValues.chooseSupplier[currentLanguage.language]"
            return-object
            clearable
          ></v-autocomplete>
        </v-card-title>

        <v-sheet v-if="firstLoad" class="px-3 pt-3 pb-3">
          <v-skeleton-loader class="mx-auto" type="table-thead"></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto"
            type="table-row-divider@5"
          ></v-skeleton-loader>
          <v-skeleton-loader class="mx-auto" type="table-tfoot"></v-skeleton-loader>
        </v-sheet>

        <v-card>
          <v-form ref="listForm" lazy-validation>
            <v-data-table
              :headers="filialHeaders[currentLanguage.language]"
              v-show="!firstLoad"
              :items="filials"
              :items-per-page="5"
              class="elevation-2"
              :loading="loading"
              :search="searchFilials"
            >
              <template v-slot:item.price="{ item }">
                <v-btn
                  small
                  @click="
                    dialog = !dialog;
                    GetPriceByFilialId(item);
                  "
                  :disabled="item.uploadingPrice"
                  color="primary"
                  >Прайс</v-btn
                >
              </template>
            </v-data-table>
          </v-form>
        </v-card>

        <v-dialog
          v-model="dialog"
          class="elevation-20"
          transition="scale-transition"
          persistent
          scrollable
        >
          <v-card tile>
            <v-toolbar dense>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Прайс {{ currentFilial }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn text @click="dialog = false">{{ stringValues.close[currentLanguage.language] }}</v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-title>
              <code>
                <small>Количество позиции: {{ price.length }}</small>
              </code>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchInPrice"
                append-icon="mdi-magnify"
                :label="stringValues.search[currentLanguage.language]"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                item-key="[index]"
                :headers="priceHeaders[currentLanguage.language]"
                :items="price"
                :search="searchInPrice"
                :items-per-page="10"
                :loading="loadingPrice"
                @click:row="SelectFilial"
                single-select
                fixed-header
                height="500"
                class="priceDatatable"
              >
                <template v-slot:item.shelfLife="{ item }">{{
                  item.shelfLife.substr(0, 10) | toDate
                }}</template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <OrderDialog></OrderDialog>
  </div>
</template>

<script>
export default {
  inject: ["theme"],
  data: () => ({
    dialog: false,
    filials: [],
    price: [],
    distributors: [],
    expanded: [],
    loading: false,
    loadingPrice: true,
    firstLoad: true,
    searchFilials: "",
    searchInPrice: "",
    currentFilial: "",
    filialHeaders: {
      "RU": [
        { text: "ID", sortable: true, value: "id" },
        { text: "Поставщик", value: "name" },
        { text: "Прайс", value: "price", sortable: false },
      ],
      "KZ": [
        { text: "ID", sortable: true, value: "id" },
        { text: "Жеткізуші", value: "name" },
        { text: "Прайс", value: "price", sortable: false },
      ],
    },
    priceHeaders: {
      "RU": [
        { text: "Id товара", sortable: true, value: "goods.id" },
        { text: "Полное название", sortable: true, value: "goods.fullName" },
        { text: "Срок годности", sortable: true, value: "shelfLife" },
        { text: "Цена", value: "goodsPrice" },
        { text: "Упаковка", value: "pack" },
        { text: "Коробка", value: "box" },
        { text: "Кратность", value: "multiplicity" },
        { text: "Остаток", value: "stored" },
        { text: "Дата прайса", value: "insertedDate" },
      ],
      "KZ": [
        { text: "Id товара", sortable: true, value: "goods.id" },
        { text: "Толық атауы", sortable: true, value: "goods.fullName" },
        { text: "Мерзімі жыл", sortable: true, value: "shelfLife" },
        { text: "Бағасы", value: "goodsPrice" },
        { text: "Упаковка", value: "pack" },
        { text: "Коробка", value: "box" },
        { text: "Кратность", value: "multiplicity" },
        { text: "Қалдық", value: "stored" },
        { text: "Дата прайса", value: "insertedDate" },
      ],
    }
  }),
  async mounted() {
    this.$store.dispatch("DISTRIBUTORS_BY_CONTEXT").then(() => {
      this.distributors = this.$store.getters.DISTRIBUTORS;
    });

    this.$store
      .dispatch("FILIALS_BY_CONTEXT")
      .then(() => {
        this.firstLoad = !this.firstLoad;
        this.filials = this.$store.getters.FILIALS;
      })
      .catch(() => {
        this.firstLoad = !this.firstLoad;
      });

    // this.$store.dispatch("CANCEL_REQUESTS").then(() => {});
  },
  methods: {
    async GetPriceByFilialId(filial) {
      this.price = [];
      this.searchInPrice = "";
      this.currentFilial = "";
      this.loadingPrice = true;
      this.$store
        .dispatch("PRICE_BY_FILIAL_ID", filial.id)
        .then(() => {
          this.price = this.$store.getters.PRICE_FILIAL;
          for (var i = 0; i < this.price.length; i++) {
            this.price[i].index = i;
          }

          this.price.forEach((element) => {
            let date = new Date(element.insertedDate);
            element.insertedDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
            // element.insertedDate = element.insertedDate.replace(/T/, ' ').replace(/\..+/, ''); 
             }
          )
          
          this.currentFilial = filial.name;
          this.loadingPrice = false;
        })
        .catch(() => {
          this.loadingPrice = false;
        });
    },
    SelectFilial(value, row) {
      window.console.log(row);
      window.console.log(value);
      row.select(true);

      let OrderDialog = {
        goodsQuantity: 1,
        show: true,
        price: Object.assign({}, value),
      };

      this.$store.commit("SET_ORDER_DIALOG", OrderDialog);
    },
    async GetFilialsByDistributorId(distributor) {
      if (distributor == undefined) this.filials = this.$store.getters.FILIALS;
      else
        this.filials = this.$store.getters.FILIALS_BY_DISTRIBUTOR_ID(
          distributor.id
        ).filials;
    },
  },
  created() {
  },
  computed: {
    stringValues: {
      get() {
        return this.$store.getters.STRING_VALUES;
      },
    },
    currentLanguage: {
      get() {
        return this.$store.getters.CURRENT_LANG;
      }
    },
  }
};
</script>

<style scoped>
.priceDatatable {
    -webkit-user-select: none;  
    -moz-user-select: none;  
    -ms-user-select: none;  
    -o-user-select: none;  
    user-select: none;
}
</style>