<template>
  <v-row>
    <v-col cols="2">
      <!-- Фильтр -->
      <v-card
        style="position: -webkit-sticky; position: sticky; top: 100px"
        shaped
      >
        <v-card-title>
          <v-icon large left color="primary">mdi-filter</v-icon>
          <span class="title font-weight-light">{{ stringValues.filter[currentLanguage.language] }}</span>
        </v-card-title>
        <v-col class="d-flex">
          <v-autocomplete
            :label="stringValues.filter[currentLanguage.language]"
            item-text="name"
            item-value="id"
            :items="filials"
            persistent-hint
            v-model="filial"
            :hint="stringValues.chooseSupplier[currentLanguage.language]"
            return-object
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex">
          <v-autocomplete
            :label="stringValues.filterOrderStatus[currentLanguage.language]"
            item-text="status"
            item-value="id"
            :items="statuses"
            v-model="status"
            persistent-hint
            :hint="stringValues.chooseOrderStatus[currentLanguage.language]"
            return-object
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex">
          <v-menu
            ref="menu.sentDateFrom"
            v-model="menu.sentDateFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="sentDateFromFormatted"
                :label="stringValues.dateBegin[currentLanguage.language]"
                persistent-hint
                prepend-icon="mdi-clock-time-eight-outline"
                v-bind="attrs"
                v-on="on"
                :hint="stringValues.selectDateDispathOrder[currentLanguage.language]"
                clearable
                @click:clear="sentDateFrom = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="sentDateFrom"
              no-title
              @input="menu.sentDateFrom = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex">
          <v-menu
            ref="menu.sentDateTo"
            v-model="menu.sentDateTo"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="sentDateToFormatted"
                :label="stringValues.dateInclusive[currentLanguage.language]"
                persistent-hint
                prepend-icon="mdi-clock-time-eight-outline"
                v-bind="attrs"
                v-on="on"
                :hint="stringValues.selectDateDispathOrder[currentLanguage.language]"
                clearable
                @click:clear="sentDateTo = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="sentDateTo"
              no-title
              @input="menu.sentDateTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex">
          <v-btn
            :loading="loading.getArchiveOrders"
            :disabled="loading.getArchiveOrders"
            @click="GetArchiveOrders()"
            block
            color="primary"
          >
          {{ stringValues.searchOrder[currentLanguage.language] }}
            <template v-slot:loader>
              <span>Ведётся поиск...</span>
            </template>
          </v-btn>
        </v-col>
        <v-col class="d-flex">
          <v-btn block
            ><download-excel
              :fetch="OrdersExcelGenerate"
              :fields="ordersExcelFields"
              name="Заказы.xls"
              worksheet="Архив"
              :footer="'Сумма заказа: ' + multipleOrderFooter"
              >{{ stringValues.excelResult[currentLanguage.language] }}</download-excel
            >
          </v-btn>
        </v-col>
      </v-card>
    </v-col>
    <v-col cols="10">
      <v-alert v-if="ordersIsEmpty" border="bottom" colored-border elevation="2"
        >Заказов не найдено, попробуйте изменить фильтр.</v-alert
      >
      <v-sheet v-if="loading.getArchiveOrders">
        <v-skeleton-loader
          v-for="n in 6"
          :key="n"
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto mb-3"
          elevation="2"
          transition="scale-transition"
        ></v-skeleton-loader>
      </v-sheet>
      <!-- Заказы -->
      <v-expansion-panels multiple focusable accordion>
        <v-expansion-panel
          class="mb-3"
          v-for="order in orders"
          :key="order.id"
          :id="`order${order.id}`"
        >
          <v-expansion-panel-header class="text-truncate">
            <!-- header -->

            <v-list dense>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ stringValues.orderNumber[currentLanguage.language] }} [Фармцентр]:
                    <v-chip small outlined color="primary">{{
                      order.id
                    }}</v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="order.filialOrderId">
                    {{ stringValues.orderNumber[currentLanguage.language] }} [{{ stringValues.provider[currentLanguage.language] }}]:
                    <v-chip small outlined color="primary">{{
                      order.filialOrderId
                    }}</v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-title>{{ order.filial.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="order.createdDate"
                    >{{ stringValues.dateCreate[currentLanguage.language] }}:
                    {{
                      new Date(order.createdDate).toLocaleString()
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle v-if="order.sentDate"
                    >{{ stringValues.departureDate[currentLanguage.language] }}:
                    {{
                      new Date(order.sentDate).toLocaleString()
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-title v-if="order.comment"
                    >Комментарий: {{ order.comment }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ stringValues.orderSatus[currentLanguage.language] }}:
                    <v-chip small outlined color="primary">{{
                      order.status
                    }}</v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="order.invoiceStatus">
                    Статус накладной:
                    <v-chip small outlined color="primary">{{
                      order.invoiceStatus
                    }}</v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="order.halykBalances.length">
                    Баланс:
                    <v-chip small outlined color="primary">{{
                      GetLastHalykBalance(order.halykBalances)
                    }}</v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ stringValues.paymentMethod[currentLanguage.language] }}:
                    <v-chip small outlined color="primary">{{
                      order.orderPayMethod
                    }}</v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <v-btn
                      v-if="order.halykTransferResponseId"
                      color="success"
                      x-small
                      depressed
                      @click.prevent.stop="
                        PayOrderInvoice(order.halykTransferResponse)
                      "
                      >Оплачено</v-btn
                    >
                    <v-btn
                      v-if="
                        order.status == 'ОТПРАВЛЕН' &&
                        order.halykTransferResponseId == null &&
                        order.orderIsPayable
                      "
                      color="primary"
                      x-small
                      depressed
                      @click.prevent.stop="PayOrderDialog(order)"
                      >Оплатить</v-btn
                    >
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-subtitle
                    >{{ stringValues.countGoods[currentLanguage.language] }}:
                    {{ order.orderDetails.length }}</v-list-item-subtitle
                  >
                  <v-list-item-title>
                    {{ stringValues.orderTotalWithDiscount[currentLanguage.language] }}:
                    {{
                      order.orderDetails
                        .map(
                          (x) => x.quantity * x.price * (1 - x.discount / 100)
                        )
                        .reduce(
                          (previousValue, currentValue) =>
                            previousValue + currentValue,
                          0
                        )
                        .toFixed(2)
                    }}
                  </v-list-item-title>
                  <v-list-item-title>
                    {{ stringValues.orderTotalWithoutDiscount[currentLanguage.language] }}:
                    {{
                      order.orderDetails
                        .map((x) => x.quantity * x.price)
                        .reduce(
                          (previousValue, currentValue) =>
                            previousValue + currentValue,
                          0
                        )
                        .toFixed(2)
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <!-- body -->
            <v-row>
              <!-- details operation -->
              <v-col cols="1" class="text-right">
                <v-card
                  elevation="0"
                  style="position: sticky; top: 0px"
                  class="pt-16"
                >
                  <div class="mb-1">
                    <v-menu top offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on: onMenu }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-btn
                              v-on="{ ...onTooltip, ...onMenu }"
                              fab
                              small
                              :disabled="order.selectedDetails.length == 0"
                            >
                              <v-icon>mdi-content-cut</v-icon>
                            </v-btn>
                          </template>
                          <span>Создать копию товаров в хранилище</span>
                        </v-tooltip>
                      </template>
                      <v-list dense max-width="230px">
                        <v-subheader v-if="storages.length == 0"
                          >У вас нет активных хранилище</v-subheader
                        >
                        <template v-for="(accessStorage, index) in storages">
                          <v-divider
                            :key="index"
                            v-if="index !== 0"
                          ></v-divider>
                          <v-list-item :key="-index" @click="0">
                            <v-list-item-content
                              @click="
                                CopyDetailsToStorage(
                                  order.selectedDetails,
                                  accessStorage.storage.id
                                )
                              "
                            >
                              <v-chip small>{{
                                accessStorage.storage.name
                              }}</v-chip>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </div>
                </v-card>
              </v-col>

              <!-- details -->
              <v-col cols="10">
                <v-data-table
                  class="elevation-3 mt-2"
                  filterable
                  v-model="order.selectedDetails"
                  :headers="headers[currentLanguage.language]"
                  :items="order.orderDetails"
                  dense
                  show-select
                  item-key="id"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-chip small outlined label color="primary">ЗАКАЗ</v-chip>
                  </template>

                  <template v-slot:item.goods.fullName="{ item }">
                    {{ item.goods.fullName }}
                    <v-chip
                      v-if="
                        item.promoGroup != null && item.isMainPromoGroup == true
                      "
                      color="#6DBFB9"
                      dark
                      x-small
                      >A</v-chip
                    >
                    <v-chip
                      v-if="
                        item.promoGroup != null &&
                        item.isMainPromoGroup == false
                      "
                      color="teal"
                      dark
                      x-small
                      >G</v-chip
                    >

                    <v-chip
                      v-if="
                        item.workloadGroup != null &&
                        item.isMainWorkloadGroup == true
                      "
                      color="#559EBF"
                      dark
                      x-small
                      >L</v-chip
                    >

                    <v-chip
                      v-if="
                        item.workloadGroup != null &&
                        item.isMainWorkloadGroup == false
                      "
                      color="#2B5666"
                      dark
                      x-small
                      >W</v-chip
                    >
                  </template>

                  <template v-slot:item.sum="{ item }">{{
                    (item.quantity * item.price).toFixed(2)
                  }}</template>

                  <template v-slot:item.shelfLife="{ item }">{{
                    item.shelfLife.substr(0, 10) | toDate
                  }}</template>

                  <template v-slot:footer>
                    <v-divider></v-divider>
                    <v-layout justify-end>
                      <v-chip class="ma-2" label justify-end>
                        <v-icon left>mdi-summit</v-icon>
                        {{ stringValues.totalAmount[currentLanguage.language] }}:
                        {{
                          order.orderDetails
                            .map((x) => x.quantity * x.price)
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue,
                              0
                            )
                            .toFixed(2)
                        }}
                      </v-chip>
                    </v-layout>
                  </template>
                </v-data-table>

                <v-divider></v-divider>
                <v-data-table
                  class="elevation-3 mt-2"
                  v-if="order.invoice"
                  filterable
                  v-model="order.selectedDetails"
                  :headers="headers[currentLanguage.language]"
                  :items="order.invoice.invoiceDetails"
                  dense
                  show-select
                  item-key="id"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-chip small outlined label color="primary"
                      >НАКЛАДНАЯ</v-chip
                    >
                  </template>
                  <!-- <template v-slot:item.sum="{ item }">{{
                    (item.quantity * item.goodsPrice).toFixed(2)
                  }}</template> -->

                  <template v-slot:item.shelfLife="{ item }">{{
                    item.shelfLife.substr(0, 10) | toDate
                  }}</template>

                  <template v-slot:footer>
                    <v-divider></v-divider>
                    <v-layout justify-end>
                      <v-chip class="ma-2" label justify-end>
                        <v-icon left>mdi-summit</v-icon>
                        {{ stringValues.totalAmount[currentLanguage.language] }}:
                        {{
                          order.invoice.invoiceDetails
                            .map((x) => x.sum)
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue,
                              0
                            )
                            .toFixed(2)
                        }}
                      </v-chip>
                    </v-layout>
                  </template>
                </v-data-table>
              </v-col>

              <!-- order operation -->
              <v-col cols="1" class="text-left">
                <v-card
                  elevation="0"
                  style="position: sticky; top: 0px"
                  class="pt-16"
                >
                  <div class="mb-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <download-excel
                          :fetch="OrderExcelGenerate"
                          :fields="ordersExcelFields"
                          :before-generate="BeforeGenerateExcel(order)"
                          :name="order.id + '-Заказ.xls'"
                          worksheet="Архив"
                          :footer="'Сумма заказа: ' + singleOrderFooter"
                        >
                          <v-btn v-on="on" fab>
                            <v-icon>mdi-microsoft-excel</v-icon>
                          </v-btn>
                        </download-excel>
                      </template>
                      <span>{{ stringValues.excelResult[currentLanguage.language] }}</span>
                    </v-tooltip>
                  </div>
                  <div class="mb-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          @click="CopyOrder(order)"
                          :loading="
                            loading.copyOrder && order.id == currentOrder.id
                          "
                          :disabled="
                            loading.copyOrder && order.id == currentOrder.id
                          "
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ stringValues.createOrderCopy[currentLanguage.language] }}</span>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-pagination v-model="options.page" :length="pagesLength"></v-pagination>
    </v-col>

    <PayOrderDialog :order="payOrder" @get-archive-orders="GetArchiveOrders" />
    <PayOrderInvoiceDialog :transferResponse="payOrderInvoice" />
  </v-row>
</template>
<script>
export default {
  data: (vm) => ({
    singleOrderFooter: null,
    multipleOrderFooter: null,
    payOrder: {
      balance: 0,
      orderDetails: [{ quantity: 0, price: 0, discount: 0 }],
      filial: { name: "" },
    },
    payOrderInvoice: {},
    ordersExcelFields: {
      "Номер заказа": "order.id",
      "Дата создания": {
        field: "order.createdDate",
        callback: (value) => {
          return `${new Date(value).toLocaleString()}`;
        },
      },
      Статус: "order.status",
      Поставщик: "order.filial.name",
      "Имя товара": "detail.goods.fullName",
      Цена: "detail.price",
      Количество: "detail.quantity",
      Скидка: "detail.discount",
      "Срок годности": {
        field: "detail.shelfLife",
        callback: (value) => {
          return `${value.substr(0, 10).toLocaleString()}`;
        },
      },
    },
    ordersExcelData: [],
    orderExcelData: [],
    sentDateFrom: null,
    sentDateTo: null,
    sentDateFromFormatted: vm.$options.filters.toDate(),
    sentDateToFormatted: vm.$options.filters.toDate(),
    menu: { sentDateFrom: false, sentDateTo: false },
    goodsPrices: [],

    loading: {
      copyOrder: false,
      getArchiveOrders: false,
    },
    dialog: {},
    show: {},
    currentOrder: {
      balance: 0,
      id: -1,
      filial: {
        name: "",
      },
      createdDate: "",
      comment: "",
    },
    headers: {
      "RU": [
        // { text: "", value: "id" },
        { text: "Название", value: "goods.fullName" },
        { text: "Форма", value: "goods.dosageForm.name" },
        // { text: "Номер", value: "goods.number" },
        { text: "Кол-во", value: "quantity" },
        { text: "Цена /Мин.", value: "price" },
        // { text: "Макс.розн.цена", value: "price2" },
        { text: "Сумма", value: "sum" },
        { text: "Скидка", value: "discount" },
        { text: "Срок годности", value: "shelfLife" },
      ],
      "KZ": [
        // { text: "", value: "id" },
        { text: "Атауы", value: "goods.fullName" },
        { text: "Нысаны", value: "goods.dosageForm.name" },
        // { text: "Номер", value: "goods.number" },
        { text: "Саны", value: "quantity" },
        { text: "бағасы/мин.", value: "price" },
        // { text: "Макс.розн.цена", value: "price2" },
        { text: "Сомасы", value: "sum" },
        { text: "Жеңілдік", value: "discount" },
        { text: "Жарамдылық мерзімі", value: "shelfLife" },
      ],
    },
    filials: [],
    statuses: [],
    filial: null,
    status: { id: 3, status: "ОТПРАВЛЕН", key: "sent" },
    options: {
      page: 1,
      itemsPerPage: 20,
    },
    ordersIsEmpty: false,
    orders: [],
    pagesLength: 0,
  }),
  methods: {
    GetLastHalykBalance(halykBalances) {
      const lastBalance = [...halykBalances].pop()?.currentBalance;

      return lastBalance;
    },
    PayOrderDialog(payOrder) {
      this.$store
        .dispatch("HALYK_FILIAL_BALANCE", payOrder.filialId)
        .then((response) => {
          payOrder.balance = response;
          this.payOrder = payOrder;
          this.$store.commit("SET_PAY_ORDER_DIALOG", true);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    PayOrderInvoice(transferResponse) {
      // this.$store
      //   .dispatch("")
      //   .then(() => {
      this.payOrderInvoice = transferResponse;
      this.$store.commit("SET_PAY_ORDER_INVOICE_DIALOG", true);
      // })
      // .catch((error) => {
      //   window.console.log(error);
      // });
    },
    BeforeGenerateExcel(order) {
      this.currentOrder = order;
    },
    OrdersExcelGenerate() {
      for (let o = 0; o < this.orders.length; o++) {
        const order = this.orders[o];
        const orderDetails = order.orderDetails;

        for (let d = 0; d < orderDetails.length; d++) {
          const detail = orderDetails[d];
          this.multipleOrderFooter += detail.price * detail.quantity;
          this.ordersExcelData.push({ order, detail });
        }
      }
      this.multipleOrderFooter = this.multipleOrderFooter.toFixed(2);
      return this.ordersExcelData;
    },
    OrderExcelGenerate() {
      this.orderExcelData = [];
      const order = this.currentOrder;
      const orderDetails = order.orderDetails;

      for (let d = 0; d < orderDetails.length; d++) {
        const detail = orderDetails[d];
        this.singleOrderFooter += detail.price * detail.quantity;
        this.orderExcelData.push({ order, detail });
      }
      this.singleOrderFooter = this.singleOrderFooter.toFixed(2);
      return this.orderExcelData;
    },
    GetArchiveOrders() {
      this.orders = [];
      this.ordersIsEmpty = false;
      this.loading.getArchiveOrders = true;
      let Pagination = {
        PageNumber: this.options.page,
        PageSize: this.options.itemsPerPage,
      };

      let SearchProperty = {
        FilialId: `${this.filial?.id ?? ""}`,
        StatusId: `${this.status?.id ?? ""}`,
        SentDateFrom: `${this.sentDateFrom ?? ""}`,
        SentDateTo: `${this.sentDateTo ?? ""}`,
      };

      let OrdersSearch = {
        Pagination,
        SearchProperty,
      };
      this.$store
        .dispatch("ARCHIVE_ORDERS", OrdersSearch)
        .then((data) => {
          this.loading.getArchiveOrders = false;
          this.orders = data.orders;

          if (this.options.page <= 1) {
            this.pagesLength =
              data.totalCount < this.options.itemsPerPage
                ? 1
                : Math.ceil(data.totalCount / this.options.itemsPerPage);
          }

          if (!this.orders.length) this.ordersIsEmpty = true;
        })
        .catch(() => {
          this.loading.getArchiveOrders = false;
        });
    },
    CopyDetailsToStorage(details, toStorageId) {
      let payload = {
        toStorageId,
        details,
      };
      this.$store
        .dispatch("COPY_DETAILS_TO_STORAGE", payload)
        .then(() => {
          let Success = {
            text: "Товары были скопированы в дефектуру.",
            timeout: 5000,
            color: "success",
          };
          this.$store.commit("SET_SNACKBAR", Success);
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    CopyOrder(order) {
      this.$store
        .dispatch("CLONE_ORDER", order.id)
        .then(() => {})
        .catch(() => {
          this.loading.copyOrder = false;
        });
    },
    GetPriceByGoodsId(goods, filialId) {
      this.goodsPrices = [];

      let payload = {
        goodsId: goods.goodsId,
        goods: goods,
      };

      this.$store
        .dispatch("PRICE_BY_GOODS_ID", payload)
        .then(() => {
          this.goodsPrices =
            this.$store.getters.PRICE_BY_GOODS_ID.prices.filter(
              (x) => x.filialId !== filialId
            );
        })
        .catch(() => {});
    },
    SelectFilial(value, quantity) {
      let OrderDialog = {
        goodsQuantity: quantity,
        show: true,
        price: Object.assign({}, value),
      };
      this.$store.commit("SET_ORDER_DIALOG", OrderDialog);
    },
    GetFilials() {
      this.$store
        .dispatch("FILIALS")
        .then(() => {
          this.filials = this.$store.getters.FILIALS;
        })
        .catch(() => {});
    },
    GetStatuses() {
      this.$store
        .dispatch("STATUSES")
        .then((data) => {
          this.statuses = data;
        })
        .catch(() => {});
    },
    GetAccessStorages() {
      this.$store
        .dispatch("ACCESS_STORAGES")
        .then(() => {})
        .catch(() => {});
    },
  },
  created() {
    this.GetArchiveOrders();
    this.GetFilials();
    this.GetStatuses();
    this.GetAccessStorages();
  },
  computed: {
    stringValues: {
      get() {
        return this.$store.getters.STRING_VALUES;
      },
    },
    currentLanguage: {
      get() {
        return this.$store.getters.CURRENT_LANG;
      }
    },
    storages: {
      get: function () {
        return this.$store.getters.ACCESS_STORAGES;
      },
    },
  },
  watch: {
    sentDateFrom() {
      this.sentDateFromFormatted = this.$options.filters.toDate(
        this.sentDateFrom
      );
    },
    sentDateTo() {
      this.sentDateToFormatted = this.$options.filters.toDate(this.sentDateTo);
      //
    },
    "options.page": function () {
      this.GetArchiveOrders();
    },
  },
};
</script>
